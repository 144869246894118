<template>

    <!-- TABELLE DEI PIATTI -->
        <table class="table table-bordered border-secondary" :class="{'bg-dark bg-opacity-10': piattoNonUscito}">
            <tbody>
                <tr><th class="text-start" colspan="2" :class="{'bgAlma text-white': !piattoNonUscito}"> {{ piatto.Tipo_Piatto__c }}: {{ piatto.Name }}</th></tr>

                <tr v-for="i in indicatori" v-bind:key="i.Id">
                    <td v-if="i.Tipo__c == 'Piatto'">{{ i.Ordinatore__c }}</td>
                    <td v-if="i.Tipo__c == 'Piatto'">
                        <div class="d-flex">
                            <div class="col" :class="{'pe-0': voto(i.Descrizione__c) < 0}">
                                <div :class="voto(i.Descrizione__c) == ''? 'col pe-0': 'col'">
                                    <select class="form-select rounded-pill py-0" :class="{'bg-danger bg-opacity-25 border-danger text-danger': voto(i.Descrizione__c) < 0 && !piattoNonUscito}"
                                    v-model="piatto.voti[i.Descrizione__c]" v-on:change="$emit('saveVotiPiatto', { indicatore: i.Descrizione__c, voto: piatto.voti[i.Descrizione__c], idPiatto: piatto.Id, commento: '', lettura: false })"
                                    :disabled="piattoNonUscito">
                                        <option disabled selected value="-1">{{ i.Descrizione__c }}</option>
                                        <option v-for="v in voti_in(i.Valutazione_in__c)" v-bind:key="v">{{ v }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <input v-if="voto(i.Descrizione__c) > -1 && voto(i.Descrizione__c) < 18" placeholder="Note"
                            class="mt-2 border" :class="{'border-danger bg-danger bg-opacity-25' : piatto.commenti[i.Descrizione__c] == ''}"
                            v-model="piatto.commenti[i.Descrizione__c]" type="text"
                            v-on:change="$emit('saveVotiPiatto', { indicatore: i.Descrizione__c, voto: piatto.voti[i.Descrizione__c], idPiatto: piatto.Id, commento: piatto.commenti[i.Descrizione__c]})">
                    </td>
                </tr>

            </tbody>
        </table>

</template>
 
 
<script>
 
export default {
    name: 'votoPiatto',
    components: {},
    props: {
        piatto: Object,
        indicatori: Array,
    },
    data() {
        return {}
    },
    computed: {
        piattoNonUscito() {
            return (!this.piatto.Ordine_di_uscita__c)
        }
    },
    mounted () {},
    methods: {
        voto(key) {
            return this.piatto.voti[key]
        },
        voti_in(num) {
            var elencoVoti = []
            for(var i = num; i >= 0; i--) {
                elencoVoti.push(i)
            }
            return elencoVoti
        },}
}
</script>