<template>
    <div v-if="$store.getters.logged && isGiurato">
        <div class="position-absolute ms-3 mt-0">
            <a v-if="!valutaEsame && !isGiurato" href="/#/home" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <a v-else-if="isGiurato && valutaEsame" @click.prevent="showElencoPrenotazioni()" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <a v-else-if="isGiurato && showAnagrafica" @click.prevent="showVoti()" class="bi bi-arrow-left-circle-fill fs-2"></a>
        </div>

        <!-- ALERT ALLERGIE -->
        <div class="alert alert-dark position-fixed w-75 text-center translate-middle-x start-50" :class="showAllergia ? '' : 'd-none'">
            <div class="row">
                <div class="col"><h4><b>{{ infoAllergie.studente }}</b></h4></div>
                <div class="col-1 pe-5"><button type="button" class="btn-close btn-lg opacity-100" @click="showAllergia = false"></button></div>
            </div>
            <div>
                <p v-if="infoAllergie.elencoAllergie && infoAllergie.elencoAllergie.length > 0" class="text-start mb-0 fw-bold">Allergie</p>
                <ul>
                    <li class="text-start" v-for="allergia in infoAllergie.elencoAllergie" v-bind:key="allergia">
                        {{ allergia }}.
                    </li>
                </ul>
                <p v-if="infoAllergie.elencoIntolleraze && infoAllergie.elencoIntolleraze.length > 0" class="text-start mb-0 fw-bold">Intolleranze</p>
                <ul>
                    <li class="text-start" v-for="intolleranza in infoAllergie.elencoIntolleraze" v-bind:key="intolleranza">
                        {{ intolleranza }}.
                    </li>
                </ul>
                <p v-if="infoAllergie.elencoDisturbi && infoAllergie.elencoDisturbi.length > 0" class="text-start mb-0 fw-bold">Disturbi dell'apprendimento</p>
                <ul>
                    <li class="text-start" v-for="disturbo in infoAllergie.elencoDisturbi" v-bind:key="disturbo">
                        {{ disturbo }}.
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="isGiurato" class="row mt-3 mb-3">
            <div class="col-auto mx-auto border rounded-3 bgAlma">
                <p class="fw-bold mb-0 text-center text-white">
                    <i class="bi bi-person-fill text-white"></i> {{ $store.state.nomeGiurato }}
                    <i class="bi bi bi-grip-vertical text-white"></i> TAV. {{ $store.state.tavoloGiurato }}
                </p>
            </div>
        </div>

        <div v-if="!valutaEsame && !showAnagrafica" class="container text-center col-lg-6" :class="showAllergia ? 'pe-none' : ''">

            <div class="row">
                <div class="col align-content-center"><h1 class="fw-bold mb-0">Elenco {{ elencoPiatti ? 'Piatti' : 'Studenti' }}</h1></div>
                <div class="col-4">
                    <div class="col">Ordina per: </div>
                    <div class="col" @click="elencoPiatti = !elencoPiatti"><p class="mb-0 bgAlma text-white rounded-3">{{ !elencoPiatti ? 'Piatti' : 'Studenti' }}</p></div>
                </div>
            </div>

            <!-- INFORMAZIONI SUGLI INDICATORI -->
            <!-- <div class="my-2">
                <div class="accordion-item">
                    <h2 class='accordion-header'>
                        <button type="button" id="accordionButton"
                        class="accordion-button rounded-1 bg-warning bg-opacity-50"
                        :class="showInfoIndicatori ? '':'collapsed'" @click="showInfoIndicatori = !showInfoIndicatori">
                            Indicazioni per la giuria d’esame
                        </button>
                    </h2>
                    <div id="accordionHeader" class="accordion-collapse collapse" :class="showInfoIndicatori ? 'show':''">
                        <div class="accordion-body" >
                            <p class="text-start mb-0" v-html="indicatori"></p>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- TABLE ORDINATI PER PIATTI -->
            <table v-if="elencoPiatti" class="table table-sm mt-2">
                <thead>
                    <tr>
                        <th class="col" colspan="2"><h3 class="fw-bold mb-0 text-start">Piatti</h3></th>
                        <th class="col-4"><h3 class="fw-bold mb-0">Valutazioni</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="piatto in uscitaPiatti" v-bind:key="piatto.Id">
                        <td class="align-middle px-0">
                            <div class="px-1 border rounded-circle bgAlma text-white">
                                <b>{{ piatto.Ordine_di_uscita__c }}</b>
                            </div>
                        </td>
                        <td class="text-start align-middle ps-2">
                            <b>{{ piatto.Name }}</b>
                            <br>{{ piatto.Tesi__r.Candidato__r.LastName }} {{ piatto.Tesi__r.Candidato__r.FirstName }}
                        </td>
                        <td class="align-middle px-0">
                            <button class="btn-sm mx-auto w-100" :class="{'btn-success active' : piatto.valutata}" @click="getEsame(piatto, 'piatto')">
                                {{ piatto.valutata ? 'Valutato' : 'Valuta' }}
                            </button>
                        </td>
                        <td class="align-middle px-0"><span v-if="piatto.Tesi__r.Opportunita__r.Allergie__c" @click="showAllergie(piatto, piatto.Tesi__r.Candidato__r.Name)" class="bi bi-file-earmark-medical text-danger fs-3 align-text-top"></span></td>
                    </tr>
                </tbody>
            </table>

            <!-- TABLE ORDINATI PER STUDENTE -->
            <table v-if="!elencoPiatti" class="table table-sm mt-2">
                <thead>
                    <tr>
                        <th class="col" colspan="2"><h3 class="fw-bold mb-0 text-start">Studenti</h3></th>
                        <th class="col-4"><h3 class="fw-bold mb-0">Valutazioni</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="e in esami" v-bind:key="e.Id">
                        <td class="align-middle px-0">
                            <div class="px-1 border rounded-circle bgAlma text-white">
                                <b>{{ e.Numerazione_allievo__c }}</b>
                            </div>
                        </td>
                        <td class="text-start align-middle ps-2">
                            <b>{{ e.Prenotazione__r.Account__r.LastName }} {{ e.Prenotazione__r.Account__r.FirstName }}</b>
                            <br>{{ e.Opportunita__r.Edizione_Corso__r.Name }}
                        </td>
                        <td class="align-middle px-0">
                            <button class="btn-sm mx-auto w-100" :class="{'btn-success active' : e.valutata}" @click="getEsame(e, 'esame')">
                                {{ e.valutata ? 'Valutato' : 'Valuta' }}
                            </button>
                        </td>
                        <td class="align-middle px-0"><span v-if="e.Opportunita__r.Allergie__c" @click="showAllergie(e.Opportunita__r, e.Prenotazione__r.Account__r.Name)" class="bi bi-file-earmark-medical text-danger fs-3 align-text-top"></span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <vediEsame v-if="valutaEsame" :name="name" :piatti="piatti" :esame="esameSelezionato" :colspan="numVotiPiatto"
        :numVotiPiatto="numVotiPiatto" :numVotiEsame="numVotiEsame" :numVotiOpzionali="numVotiOpzionali" :indicatori="elencoIndicatori"
        v-on:setStudenteAnagrafica="setStudenteAnagrafica($event)"></vediEsame>

        <anagraficaStudente v-if="showAnagrafica" :studenteIds="studenteIds" :nome="nomeStudente"></anagraficaStudente>

    </div>
</template>

<script>
import vediEsame from '../components/vediEsame.vue'
import anagraficaStudente from '../components/anagraficaStudente.vue'

export default {
    name: 'Esami',
    components:{
        vediEsame,
        anagraficaStudente
    },
    data() {
        return {
            name: '',
            elencoPiatti: false,
            valutaEsame: false,
            piatti: [],
            esameSelezionato: {},

            mVotiEsami: new Map(),
            qtaVotiEsame: [],
            numVotiPiatto: 0,
            numVotiEsame: 0,
            numVotiTot: 0,
            numVotiOpzionali: 0,

            esami: [],
            prenotIds: [],

            mapTesiPrenot: new Map(),
            infoPrenotazioni: {},
            infoAllergie: {},
            showAnagrafica: false,
            studenteIds: {},
            nomeStudente: '',
            showAllergia: false,
            uscitaPiatti: [],

            elencoIndicatori: [],
            showInfoIndicatori: false,
            indicatori: `<ul>
                            <li>Ogni candidato presenterà <b>un piatto</b> un <b>due versioni</b>: la prima sarà una riproduzione fedele della ricetta tradizionale, mentre la seconda sarà una versione personale e creativa dello stesso piatto.</li>
                            <li>Il piatto viene scelto dal candidato in base alle esperienze fatte durante il periodo di formazione in Italia. <b>La scelta non è vincolata al luogo di stage.</b></li>
                            <li>La ricetta tradizionale sarà frutto di ricerca sul campo (interviste, assaggi, incontri con chef e produttori) e teorica (fonti scritte e online). </li>
                            <li>La ricetta personale sarà una rielaborazione creativa della prima nella quale saranno applicate le conoscenze tecniche acquisite.</li>
                            <li>L’esame si svolge in un’<b>unica sessione</b> in cui tutta la classe presenta prima il piatto tradizionale e di seguito il piatto personale.</li>
                            <li>Ad ogni piatto, il candidato proporrà <b>una bevanda in abbinamento</b> (NB: la bevanda può essere la stessa per entrambe i piatti).</li>
                            <li>I candidati metteranno a disposizione dei giurati un booklet che guidi il giurato attraverso il proprio lavoro.</li>
                            <li><b>Il piatto personale verrà presentato alla giuria direttamente dal candidato.</b></li>
                            <li><b>Al termine dell’esame</b> la giuria avrà la facoltà di chiamare individualmente i candidati per <b>eventuali domande o considerazioni</b> sul lavoro svolto.</li>
                            <li>Ogni giurato avrà a disposizione una <b>scheda di valutazione</b> da compilare entro fine sessione.</li>
                        </ul>`
        };
    },
    computed: {
        isGiurato: function() {
            if(this.$store.state.nomeGiurato != null && this.$store.state.nomeGiurato != '') return true
            return false
        }
    },
    created(){
        console.log('created di Esami.vue')
        this.$store.commit('GET_GIURATO')

        this.getPiattiEdEsami()

        setInterval(() => {
            this.getPiattiEdEsami()
        }, 15000)
    },
    methods:{
        getPiattiEdEsami() {
            var data = {
                numTavolo: this.$store.state.tavoloGiurato
            }

            Promise.all([
                this.$store.dispatch('getListaEsami', data),
                this.$store.dispatch('getPiattiPerEsame', data)
            ]).then((resp) => {
                this.esami = resp[0]
                this.uscitaPiatti = resp[1]

                this.$store.dispatch('getCondizioniEsame', this.esami[0].Opportunita__r.Edizione_Corso__r.Corso__c)
                .then( resp => {
                    this.prenotIds = []
                    this.esami.forEach( e => {
                        this.prenotIds.push(e.Prenotazione__c)
                    })

                    this.numVotiPiatto = resp.numVotiPiatto
                    this.numVotiEsame = resp.numVotiEsame
                    this.numVotiTot = resp.numVotiTot
                    this.numVotiOpzionali = resp.numVotiOpzionali
                    this.elencoIndicatori = resp.indicatori

                    this.getQtaVotiEsami()
                })
            })
        },
        showElencoPrenotazioni() {
            console.log('--> showElencoPrenotazioni')
            this.name = ''
            this.valutaEsame = false
            this.showAnagrafica = false
            this.esameSelezionato = []
            this.getQtaVotiEsami()
        },
        getQtaVotiEsami() {

            var data = {
                prenotazioni: this.prenotIds,
                idDocente: this.$store.state.userprofile.Id, 
                nomeGiurato: this.$store.state.nomeGiurato
            }
            this.$store.dispatch('getQtaVoti', data)
            .then(resp => {
                if(resp != null && resp.qtaVotiEsami != null) {
                    this.qtaVotiEsame = resp.qtaVotiEsami
                    for(var qve of this.qtaVotiEsame) {

                        // controlla voti x piatto
                        if(qve.votiDati == this.numVotiPiatto) this.mVotiEsami.set(qve.Piatto__c, true)
                        else this.mVotiEsami.set(qve.Piatto__c, false)
                    }
                }
                
                var up = []
                this.uscitaPiatti.forEach( p => {
                    if(p.valutata == null) p['valutata'] = false
                    if(this.mVotiEsami.get(p.Id)) p.valutata = true
                    up.push(p)
                })
                this.uscitaPiatti = up
            })
        },
        showAllergie(piatto, studente) {
            console.log('--> showAllergie')
            console.log(piatto, studente)
            var elencoAllergie = []
            var elencoDisturbi = []
            var elencoIntolleraze = []
            if(this.elencoPiatti){
                if(piatto.Tesi__r.Opportunita__r.Allergie__c) piatto.Tesi__r.Opportunita__r.Allergie__c.split(';').forEach( a => { elencoAllergie.push(a) })
                if(piatto.Tesi__r.Opportunita__r.Intolleranze__c) piatto.Tesi__r.Opportunita__r.Intolleranze__c.split(';').forEach( i => { elencoIntolleraze.push(i) })

                if(piatto.Tesi__r.Opportunita__r.Disturbi_dell_apprendimento__c) piatto.Tesi__r.Opportunita__r.Disturbi_dell_apprendimento__c.split(';').forEach( d => { elencoDisturbi.push(d) })
            }else{
                if(piatto.Allergie__c) piatto.Allergie__c.split(';').forEach( a => { elencoAllergie.push(a) })
                if(piatto.Intolleranze__c) piatto.Intolleranze__c.split(';').forEach( i => { elencoIntolleraze.push(i) })

                if(piatto.Disturbi_dell_apprendimento__c) piatto.Disturbi_dell_apprendimento__c.split(';').forEach( d => { elencoDisturbi.push(d) })
            }
            this.showAllergia = true

            this.infoAllergie = {
                studente: studente,
                elencoAllergie: elencoAllergie,
                elencoIntolleraze: elencoIntolleraze,
                elencoDisturbi: elencoDisturbi
            }
        },
        setStudenteAnagrafica(p) {
            console.log('setStudenteAnagrafica')
            this.showAnagrafica = true
            this.valutaEsame = false
            this.studenteIds = {
                opptyId: p.Opportunita__c,
                accountId: p.Account__c
            }
            this.nomeStudente = p.Account__r.Name
        },
        showVoti() {
            console.log('--> showElencoPrenotazioni')
            this.showAnagrafica = false
            this.valutaEsame = true
        },
        getEsame(record, tipo) {
            console.log('--> getEsame')

            var idPrenotazione = ''
            if(tipo == 'esame') {
                idPrenotazione = record.Prenotazione__c
                this.name = record.Prenotazione__r.Account__r.Name
            } else if(tipo == 'piatto') {

                this.esami.forEach( e => {
                    if(e.Tesi__c == record.Tesi__c) idPrenotazione = e.Prenotazione__c
                })

                this.name = record.Tesi__r.Candidato__r.Name
            }
            console.log('idPrenotazione',idPrenotazione)

            this.$store.dispatch('getEsame', idPrenotazione)
            .then( esame => {

                esame['indicatoriEsame'] = {}
                esame['commentiIndicatoriEsame'] = {}
                this.elencoIndicatori.forEach( i => {
                    if(i.Tipo__c == 'Esame') {
                        esame.indicatoriEsame[i.Descrizione__c] = -1
                        esame.commentiIndicatoriEsame[i.Descrizione__c] = ''
                    }
                })
                esame['votiEsame'] = []
                if(esame.Voti_Esami__r && esame.Voti_Esami__r.records) esame.votiEsame = esame.Voti_Esami__r.records
                
                this.$store.dispatch('getPiattiStudente', {idTesi: esame.Tesi__c})
                .then(resp => {
                    console.log('piatti: ',resp);

                    esame['mediaIndicatoriEsame'] = 0
                    esame['mediaTotale'] = 0

                    for(var piatto of resp) {
                        var voti = {}
                        var commenti = {}
                        this.elencoIndicatori.forEach( i => {
                            if(i.Tipo__c == 'Piatto') {
                                voti[i.Descrizione__c] = -1
                                commenti[i.Descrizione__c] = ''
                            }
                        })
                        piatto['voti'] = voti
                        piatto['commenti'] = commenti
                        piatto['listaVoti'] = []
                        piatto['media'] = 0
                    }

                    this.esameSelezionato = esame
                    this.piatti = resp
                    
                    this.valutaEsame = true
                })
            })
            /*
            this.$store.dispatch('getEsameStudente', {giornoEsame: this.oggi, idAccount:this.$store.state.userprofile.Id, prenotazione: prenotazione})
            .then(resp => {

                var esame = resp[0]
                if(esame.Tesi__c) {
                    this.name = esame.Opportunita__r.Account.Name
                    var idTesi = esame.Tesi__c
                    console.log(idTesi)

                    console.log('esame',esame)
                    var indicatoriPiatti = esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_piatti__c
                    var indicatoriEsame = 0
                    if(esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_esami__c) indicatoriEsame = esame.Edizione_Corso__r.Corso__r.N_indicatori_valutazione_esami__c
                    
                    esame['indicatoriEsame'] = {}
                    esame['commentiIndicatoriEsame'] = {}
                    for(var i = 1; i <= indicatoriEsame; i++) {
                        var indicatore = esame.Edizione_Corso__r.Corso__r['Indicatore_valutazione_'+i+'__c']
                        esame.indicatoriEsame[indicatore] = 0
                        esame.commentiIndicatoriEsame[indicatore] = ''
                    }
                    this.esameSelezionato = esame
                    
                    this.$store.dispatch('getPiattiEValutazione', {idTesi: idTesi})
                    .then(resp => {
                        console.log('piatti: ',resp);

                        this.esameSelezionato['mediaIndicatoriEsame'] = 0
                        this.esameSelezionato['mediaTotale'] = 0

                        for(var piatto of resp) {
                            var voti = {}
                            var commenti = {}
                            for(var i = indicatoriEsame+1; i <= indicatoriEsame+indicatoriPiatti; i++) {
                                var indicatore = esame.Edizione_Corso__r.Corso__r['Indicatore_valutazione_'+i+'__c']
                                voti[indicatore] = ''
                                commenti[indicatore] = ''
                            }
                            piatto['voti'] = voti
                            piatto['commenti'] = commenti
                            piatto['listaVoti'] = []
                            piatto['media'] = 0
                        }
                        this.piatti = resp
                        
                        this.valutaEsame = true
                    })
                } else alert('Questo studente non ha una tesi valida per questo esame.')
            })
            */
        }
    }
  }
</script>
