<template>
    <div v-if="$store.getters.logged">
        <div class="position-absolute ms-3 mt-0">
            <a v-if="Object.keys(selectedTesi).length==0" href="/#/home" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <a v-if="Object.keys(selectedTesi).length > 0 && !modificaGiudizio" @click.prevent="showElencoTesi()" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <a v-if="modificaGiudizio" @click.prevent="showTesi(false)" class="bi bi-arrow-left-circle-fill fs-2"></a>
        </div>

        <!-- !!! se sono account chiama Alice e piangi -->
        <!-- ALERT ALLERGIE -->
        <div class="alert alert-dark position-fixed w-75 text-center translate-middle-x start-50" :class="showAllergia ? '' : 'd-none'">
                <div class="row">
                    <div class="col"><h4>Dati di <b>{{ infoStudente.studente }}</b></h4></div>
                    <div class="col-1 pe-5"><button type="button" class="btn-close btn-lg opacity-100" @click="closeAllergie()"></button></div>
                </div>
                <ul>
                    <li class="text-start" v-if="infoStudente.disturbi"><b>Disturbi dell'apprendimento:</b></li>
                    <ul>
                        <li class="text-start" v-for="disturbo in infoStudente.disturbi" v-bind:key="disturbo">
                            {{disturbo }}.
                        </li>
                    </ul>


                    <li class="text-start" v-if="infoStudente.allergie"><b>Allergie:</b></li>
                    <ul>
                        <li class="text-start" v-for="allergia in infoStudente.allergie" v-bind:key="allergia">
                            {{allergia }}.
                        </li>
                    </ul>

                    
                    <li class="text-start" v-if="infoStudente.intolleranze"><b>Intolleranze:</b></li>
                    <ul>
                        <li class="text-start" v-for="intolleranza in infoStudente.intolleranze" v-bind:key="intolleranza">
                            {{intolleranza }}.
                        </li>
                    </ul>
                </ul>
            </div>

        <div v-if="Object.keys(selectedTesi).length==0" class="container text-center col-lg-6" :class="showAllergia ? 'pe-none' : ''">
            <div class="row">
                <h1 class="fw-bold">Elenco Tesi</h1>
            </div>

            <!-- SELEZIONA EDIZIONE CORSO -->
            <div class="row mx-2 my-1">
                <select class="form-select rounded-pill" v-model="selectedOptionCorsoId" v-on:change="generateSezione()">
                    <option value="" selected>Seleziona Edizione Corso</option>
                    <!-- <option v-for="corso in corsi" v-bind:key="corso.Edizione_Corso__c" :value="corso.Edizione_Corso__c">{{ corso.Name }}</option> -->
                    <option v-for="corso in corsi" v-bind:key="corso.Id" :value="corso.Id">{{ corso.Name }}</option>
                </select>
            </div>

            <!-- SELEZIONA SEZIONE -->
            <div v-if="showElencoSezioni" class="row mx-2 my-1">
                <select class="form-select rounded-pill" v-model="selectedOptionSezione" v-on:change="getStudentiPerGiudizio()">
                    <option value="" selected>Seleziona sezione</option>
                    <option v-for="sezione in sezioni" v-bind:key="sezione">{{ sezione }}</option>
                </select>
            </div>

            <div v-if="selectedOptionCorsoId != ''" class="row mx-2 mt-3">
                <p v-if="fase" class="fw-bold mb-0 text-center">{{ fase }} tesi</p>
                <span v-if="fase == 'Esposizione'" class="fw-bold">
                    {{ oggi }}<span v-if="showSezione"> - Sezione: {{ selectedOptionSezione == '' ? 'TUTTE': selectedOptionSezione }}</span>
                </span>
            </div>

            <table class="table table-sm mt-2">
                <thead>
                    <tr>
                        <th v-if="fase == 'Esposizione'" class="col-2"><h5 class="fw-bold mb-0 text-start">Orario</h5></th>
                        <th class="col-4"><h5 class="fw-bold mb-0 text-start">Studente</h5></th>
                        <th class="col-3"><h5 class="fw-bold mb-0 text-start">Ed. Corso</h5></th>
                        <th class="col-2"><h5 class="fw-bold mb-0">Tesi</h5></th>
                        <th class="col-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="oppty in opportunita" v-bind:key="oppty.Id">
                        <th v-if="fase == 'Esposizione'" class="text-start align-middle ps-2">
                            {{ oppty.OraInizio }}
                        </th>
                        <th class="text-start align-middle ps-2">
                            <!-- <small v-if="oppty.Ripescato__c" class="fa-2xs">RIPESCATO<br></small> -->
                            {{ oppty.Account.LastName }} {{ oppty.Account.FirstName }}
                        </th>
                        <td class="text-start align-middle ps-2">
                            {{ oppty.Sotto_commessa__c }}
                            <span v-if="fase == 'Esposizione' && showSezione && selectedOptionSezione == ''"> ({{ oppty.Gruppo_Esposizione_Tesi__c }})</span>
                        </td>
                        <td class="px-0 align-content-center">
                            <button v-if="!oppty.valutata" class="btn-sm mx-auto w-100 text-nowrap" :class="{'bg-secondary bg-opacity-25': oppty.senzaFileTesi}" 
                            @click="getGiudizi(oppty)" :disabled="oppty.senzaFileTesi">Da valutare</button>
                            <button v-else class="btn-sm mx-auto btn-success active w-100" @click="getGiudizi(oppty)" :disabled="oppty.senzaFileTesi">Valutato</button>
                        </td>
                        <!-- !!! -->
                        <!-- <td class="px-0 align-content-center"><span v-if="oppty.Allergie__c" @click="showAllergie(oppty.Allergie__c, oppty.Account.Name)" class="bi bi-file-earmark-medical text-danger fs-3 align-text-top"></span></td> -->
                        <td class="px-0 align-content-center"><span v-if="oppty.Allergie__c || oppty.Intolleranze__c || oppty.Disturbi_dell_apprendimento__c" @click="showDatiStudente(oppty)" class="bi bi-file-earmark-medical text-danger fs-3 align-text-top"></span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <vediGiudizi v-if="Object.keys(selectedTesi).length > 0 && !modificaGiudizio" :name="name" :mGiudizi="giudizi" :docenti="docenti" :mediaTesi="mediaTesi" :fase="fase" @mostraTesi="showTesi(true)"></vediGiudizi>
        <vediTesi v-if="modificaGiudizio" :name="name" :tesi="selectedTesi" :giudizio="giudizio" :info="info" :fase="fase" @goBack="showTesi(false)" @updateVoti="updatePulsanti($event)"></vediTesi>
    </div>
</template>

<script>
import { create, all } from "mathjs";
const math = create(all);

import vediTesi from '../components/vediTesi.vue'
import vediGiudizi from '../components/vediGiudizi.vue'
export default {
    name: 'Tesi',
    components:{
        vediTesi,
        vediGiudizi
    },
    data() {
        return {
            corsi: [],
            corso: {},
            sezioni: [],
            selectedOptionCorso: '',
            selectedOptionCorsoId: '',
            showSezione: false,
            selectedOptionSezione: '',
            opportunita: [],
            selectedTesi:{},
            giudizi: {},
            mGiudizi: new Map(),
            docenti: {},
            modificaGiudizio: false,
            name: '',
            giudizio: {},
            mediaTesi: 0,
            oppty: {},
            info: {},
            piatti: [],
            mTesi: [],
            fase: '',
            infoAllergie: {},
            showAllergia: false,

            oggi: new Date(),

            infoStudente: {},
        
        }
    },
    computed: {
        showElencoSezioni: function() {
            return this.showSezione || !this.fase
        }
    },
    created(){
        this.mediaTesi = 0
        var data = this.oggi.toISOString().split('T')[0]
        this.oggi = data.split('-')[2] +'/'+ data.split('-')[1] +'/'+ data.split('-')[0]
        this.getCorso();
    },
    methods:{
        getCorso:function(){
            var self=this;
            console.log('getCorso tesi');
            this.$store.dispatch('getCorsoTesiActive')
            .then(resp => {
                console.log('corsi: ',resp);
                self.corsi=resp;
            })
        },
        getCondizioneTesi() {
            console.log('getCondizioneTesi Tesi.vue');
            console.log(this.selectedOptionCorsoId)
            this.$store.dispatch('getCondizioneTesi', {idCorso : this.selectedOptionCorsoId})
            .then(resp => {
                console.log('info google sheet: ',resp);
                if(resp.Info_Google_Sheet__c) this.info = JSON.parse(resp.Info_Google_Sheet__c)
                else console.log('Il campo Condizione_Tesi__r.Info_Google_Sheet__c è vuoto')
            })
        },
        generateSezione:async function(){
            this.sezioni=[];
            this.selectedOptionSezione = ''
            this.showSezione = false
            this.fase = ''
            this.corso = {}
            for(var corso of this.corsi) {
                // if(corso.Edizione_Corso__c == this.selectedOptionCorsoId) this.corso = corso
                if(corso.Id == this.selectedOptionCorsoId) this.corso = corso
            }

            const startCharCode = 'A'.charCodeAt(0);
            console.log("NUMERO SEZIONI ",this.corso.Numero_sezioni__c)
            for(let i=0; i<this.corso.Numero_sezioni__c; i++) {
                this.sezioni.push(String.fromCharCode(startCharCode+i));
            }

            if(this.selectedOptionCorsoId) this.getCondizioneTesi()
            if(this.corso.Periodo_Valutazione_Lettura_Tesi__c) {
                this.fase = 'Lettura'
                this.showSezione = true
            }
            if(this.corso.Periodo_Valutazione_Esposizione_Tesi__c) {
                this.fase = 'Esposizione'
                this.getStudentiPerGiudizio()
            }
            this.opportunita = []
        },
        getStudentiPerGiudizio() {
            console.log('getStudentiPerGiudizio')
            console.log('edCorsoId: '+this.selectedOptionCorsoId, 'sezione: '+this.selectedOptionSezione)
            
            this.info['codiceCorso'] = this.corso.Name.split('-')[0].trim()
            this.info['sezione'] = this.selectedOptionSezione
            this.info['LastName'] = this.$store.state.userprofile.LastName
            this.info['FirstName'] = this.$store.state.userprofile.FirstName

            var data = {
                edCorso: this.selectedOptionCorsoId,
                sezione: this.selectedOptionSezione,
                idAccount: this.$store.state.userprofile.Id,
                periodo: this.fase
            }
            
            this.$store.dispatch('getStudentiPerGiudizio', data)
            .then(resp => {

                var opptys = []
                var sezioniEsposizione = new Set()
                for(var tesi of resp) {
                    var opp = tesi.Opportunita__r

                    opp['senzaFileTesi'] = true
                    if(tesi.idFile__c) opp.senzaFileTesi = false
                    opp['valutata'] = false
                    console.log('Giudizi_Tesi__r',tesi.Giudizi_Tesi__r)
                    if(tesi.Giudizi_Tesi__r && tesi.Giudizi_Tesi__r.records.length > 0) {
                        for(var gt of tesi.Giudizi_Tesi__r.records) {
                            if(gt.Tipo__c == this.fase || !this.fase) {
                                opp.valutata = true
                                break
                            }
                        }
                    }
                    if(this.fase == 'Esposizione') {
                        var data = new Date(tesi.Data_Esposizione_Tesi__c)
                        opp['OraInizio'] = data.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})

                        console.log(tesi.Opportunita__r.Account.Name, tesi.Gruppo_Esposizione_Tesi__c)
                        if(tesi.Gruppo_Esposizione_Tesi__c != null) {
                            opp['Gruppo_Esposizione_Tesi__c'] = tesi.Gruppo_Esposizione_Tesi__c
                            sezioniEsposizione.add(tesi.Gruppo_Esposizione_Tesi__c)

                            if(tesi.Gruppo_Esposizione_Tesi__c == this.selectedOptionSezione) {
                                this.mTesi[opp.Id] = tesi
                                opptys.push(opp)
                                continue
                            }
                        }
                    }

                    console.log('niente continue')
                    this.mTesi[opp.Id] = tesi
                    opptys.push(opp)

                }

                if(this.selectedOptionSezione == '' && sezioniEsposizione.size > 0) {
                    this.sezioni = Array.from(sezioniEsposizione).sort()
                    this.showSezione = true
                }

                console.log('opptys', opptys)
                this.opportunita = opptys
            })
        },
        getGiudizi(oppty) {
            console.log('getGiudizi')
            this.mGiudizi = new Map()
            this.docenti = {}
            var tesi = this.mTesi[oppty.Id]
            this.oppty = oppty
            this.selectedTesi = tesi
            this.name = oppty.Account.Name
            console.log('selectedTesi', tesi)

            this.$store.dispatch('getGiudizi', {idTesi: tesi.Id})
            .then(resp => {
                console.log('getGiudizi resp',resp)

                var idDocente = this.$store.state.userprofile.Id
                var sommaVoti = 0
                for(var r of resp) {
                    r["buttonNoteGiudizio"] = 'buttonNote'+r.Id
                    r["buttonGiudGiudizio"] = 'buttonGiudizio'+r.Id
                    r["noteGiudizio"] = 'note'+r.Id
                    r["giudGiudizio"] = 'giudizio'+r.Id

                    if(!this.mGiudizi.has(r.Docente__c)) this.mGiudizi.set(r.Docente__c, [r])
                    else this.mGiudizi.get(r.Docente__c).push(r)

                    if(r.Docente__c == idDocente && r.Tipo__c == this.fase) this.giudizio = r
                    this.docenti[r.Docente__c] = r.Docente__r.Name
                    sommaVoti += r.Voto__c
                }
                
                if(resp.length > 0) this.mediaTesi = math.round(sommaVoti / resp.length, 2)
                if(tesi.Media_Voti_Lettura__c && tesi.Media_Voti_Lettura__c != this.mediaTesi) {
                    tesi.Media_Voti_Lettura__c = this.mediaTesi
                    // TODO: il calcolo della media deve essere lato Salesforce (probabilmente un trigger)
                    // this.$store.dispatch('saveTesi', {tesi: tesi})
                    // .then(resp => { console.log(resp.Media_Voti_Lettura__c) })
                }

                var haVotoLettura = this.mGiudizi.has(idDocente) && this.mGiudizi.get(idDocente).length == 1 && this.fase == 'Esposizione'

                console.log(!this.mGiudizi.has(idDocente) +' '+haVotoLettura)
                if(!this.mGiudizi.has(idDocente) || haVotoLettura) {
                    var g = {
                        Docente__r: { Name: this.$store.state.userprofile.Name },
                        Docente__c: this.$store.state.userprofile.Id,
                        Voto__c: '',
                        Note__c: '',
                        Giudizio__c: '',
                        noteGiudizio: 'note',
                        giudGiudizio: 'giudizio',
                        buttonNoteGiudizio: 'buttonNote',
                        buttonGiudGiudizio: 'buttonGiudizio',
                        Tipo__c: this.fase
                    }

                    if(haVotoLettura) this.mGiudizi.get(g.Docente__c).push(g)
                    else {
                        this.docenti[g.Docente__c] = g.Docente__r.Name
                        this.mGiudizi.set(g.Docente__c, [g])
                    }
                    this.giudizio = g
                }

                this.giudizi = Object.fromEntries(this.mGiudizi)
                console.log('this.mGiudizi',this.mGiudizi)
            })
        },
        updatePulsanti(idOppty) {
            for(var o of this.opportunita) {
                if(o.Id == idOppty) {
                    o['valutata'] = true
                    break
                }
            }
        },
        showTesi(modGiud) {
            console.log('modGiud',modGiud)
            this.modificaGiudizio = modGiud
            if(!modGiud) { this.getGiudizi(this.oppty) }
        },
        showElencoTesi() {
            console.log('showElencoTesi')
            this.selectedTesi = {}
            this.name = ''
            this.modificaGiudizio = false
            this.giudizi = {}
            this.mGiudizi = new Map()
            this.giudizio = {}
            this.oppty = {}
        },
        showAllergie(allergie, studente) {
            console.log('--> showAllergie')
            console.log(allergie, studente)

            this.showAllergia = true
            console.log('this.showAllergia',this.showAllergia)

            this.infoAllergie = {
                studente: studente,
                elencoAllergie: allergie.split(';')
            }
        },
        showDatiStudente(studente) {
            console.log('--> showDatiStudente')
            console.log(studente)

            this.infoStudente.studente = studente.Account.LastName + " " + studente.Account.FirstName;
            if(studente.Disturbi_dell_apprendimento__c) this.infoStudente.disturbi = studente.Disturbi_dell_apprendimento__c.split(';');
            if(studente.Intolleranze__c) this.infoStudente.intolleranze = studente.Intolleranze__c.split(';');
            if(studente.Allergie__c) this.infoStudente.allergie = studente.Allergie__c.split(';');
            
            this.showAllergia = true
            console.log('this.showDatiStudente',this.showAllergia)

            
            console.log(this.infoStudente, "infoStudente")
        },
        closeAllergie(){
            this.showAllergia = false
            this.infoStudente = {};
        }
    }
  }
</script>
