<template>

    <div class="modal" :class="{'d-block bg-secondary bg-opacity-50': show}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <i class="bi bi-x" v-on:click.prevent="$emit('click', 'Annulla')"></i>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer row">
                    <div class="col" v-for="(b, bindex) of buttons" v-bind:key="b">
                        <button class="button mx-auto" v-bind:class="{'bg-white text-dark':bindex==1,'is-danger':bindex==2}" v-on:click="$emit('click', bindex)">{{ b }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
    
<script>

export default {
    name: 'modal',
    components: {},
    props: {
        title: String,
        buttons: Array,
        show: Boolean,
    },
    data() {
      return {}
    },
    computed: {},
    mounted: function() {},
    methods: {}
}
</script>