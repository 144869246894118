<template>
    <div class="container text-center col-lg-6">
 
        <div class="mb-4">
            <h1 class="fw-light">Esame di <span class="fw-bold">{{ name }}</span> <i class="bi bi-info-circle-fill" @click="showAnagrafica()"></i></h1>
        </div>

        <div>
            <div class="col">
                <h4 class="fw-bold mb-2 text-start">Indicatori</h4>
            </div>
            <div><ol>
                <li class="text-start" v-for="i in indicatori" v-bind:key="i.Id">{{ i.Descrizione__c }}</li>
            </ol></div>
        </div>

        <!-- TABELLA CON INDICATORI ESAME -->
        <div>
            <div v-for="i in indicatoriEsame" v-bind:key="i.Id">
                <div>
                    <div class="col">
                        <votoEsame :indicatore="i" :voto="esame.indicatoriEsame[i.Descrizione__c]" :commento="esame.commentiIndicatoriEsame[i.Descrizione__c]"
                        @saveVotiIndicatoriEsame="saveVotiIndicatoriEsame($event)"></votoEsame>
                    </div>
                </div>
            </div>

            <div v-for="piatto of piatti" v-bind:key="piatto.Id">
                <div class="d-flex">
                    <div class="col">
                        <votoPiatto :piatto="piatto" :indicatori="indicatori" @saveVotiPiatto="saveVotiPiatto($event)"></votoPiatto>
                    </div>
                    <div class="ms-1 align-content-end">
                        <table class="table table-bordered border-secondary" :class="{'bg-dark bg-opacity-10' : !piatto.Ordine_di_uscita__c}">
                            <tbody>
                                <tr><th :class="{'bgAlma text-white' : piatto.Ordine_di_uscita__c}" scope="col">Media</th></tr>
                                <tr>
                                    <td :class="{'fw-bold bg-danger bg-opacity-25' : piatto.media < 18 && piatto.Ordine_di_uscita__c}">
                                        {{ piatto.media }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-for="i in indicatoriOpzionali" v-bind:key="i.Id">
                <div v-if="i.Tipo__c == 'Esame' && i.Opzionale__c">
                    <div class="col">
                        <votoEsame :indicatore="i" :voto="esame.indicatoriEsame[i.Descrizione__c]" :commento="esame.commentiIndicatoriEsame[i.Descrizione__c]"
                        @saveVotiIndicatoriEsame="saveVotiIndicatoriEsame($event)"></votoEsame>
                    </div>
                </div>
            </div>
        </div>

        <!-- MEDIA CON VOTO FINALE -->
        <div class="d-flex mb-3">
            <div class="col pe-0">
                <h4 class="fw-bold py-2 rounded-start bgAlma text-white">Media totale</h4>
            </div>
            <div class="col-2 col-lg-1 col-md-1 ps-0 pe-0">
                <h4 class="fw-bold py-2 rounded-end align-content-center bgAlma text-white">{{ mediaTotale + punti}}</h4>
            </div>
        </div>
 
    </div>
 
 </template>
 
 
 <script>
 import { create, all } from "mathjs";
 const math = create(all);
 import votoEsame from '../components/votoEsame.vue'
 import votoPiatto from '../components/votoPiatto.vue'
 
 export default {
    name: 'vediEsame',
    components: {
        votoEsame, votoPiatto
    },
    props: {
        name: String,
        piatti: Array,
        esame: Object,
        colspan: Number,
        numVotiPiatto: Number,
        numVotiEsame: Number,
        numVotiOpzionali: Number,
        indicatori: Array,
    },
    data() {
        return {
            mediaIndicatoriEsame: 0,
            mediaTotale: 0,
            mTipoVoto: new Map(),

            punti: 0,
        }
    },
    computed: {
        indicatoriEsame() {
            return this.indicatori.filter( i =>  i.Tipo__c == 'Esame' && !i.Opzionale__c )
        },
        indicatoriOpzionali() {
            return this.indicatori.filter( i =>  i.Tipo__c == 'Esame' && i.Opzionale__c )
        },
    },
    mounted () {
        console.log('mounted di vediEsame.vue')
        console.log('this.esame',this.esame)
        console.log('this.esame.votiEsame', this.esame.votiEsame)

        if(this.esame.votiEsame && this.esame.votiEsame.length > 0) {
            console.log(this.esame.votiEsame)
            for(var votoEsame of this.esame.votiEsame) {
                if(votoEsame.Piatto__c) this.mTipoVoto.set(votoEsame.Tipo__c + votoEsame.Piatto__c, votoEsame.Id)
                else this.mTipoVoto.set(votoEsame.Tipo__c, votoEsame.Id)
                if(votoEsame.Piatto__c) {
                    this.saveVotiPiatto(
                        {   indicatore: votoEsame.Tipo__c, voto: votoEsame.Voto__c, 
                            idPiatto: votoEsame.Piatto__c, commento: votoEsame.Commento__c, lettura: true
                        }
                    )
                } else this.saveVotiIndicatoriEsame(
                    {   indicatore: votoEsame.Tipo__c, voto: votoEsame.Voto__c, 
                        commento: votoEsame.Commento__c, lettura: true
                    }
                )
            }
        } 
    },
    methods: {
        showAnagrafica(){
            console.log('showAnagrafica')
            var p = {
                Opportunita__c: this.esame.Opportunita__c,
                Account__c: this.esame.Opportunita__r.AccountId,
                Account__r: { Name: this.name }
            }
            console.log(p)
            this.$emit('setStudenteAnagrafica', p)
        },
        saveVotiPiatto({indicatore, voto, idPiatto, commento, lettura}) {
            console.log('indicatore', indicatore)
            console.log('voto', voto, typeof(voto))
            console.log('idPiatto', idPiatto)
 
            var statoPiatto = ''
            for(var piatto of this.piatti) {
                if(piatto.Id == idPiatto) {
                    console.log('piatto', piatto)
                    piatto.voti[indicatore] = Number(voto)
                    piatto.commenti[indicatore] = commento

                    statoPiatto = piatto.Stato__c
 
                    piatto.media = this.calcolaVotoFinale(piatto.voti, 'Piatto')
                }
                console.log(piatto.voti)
            }
 
            if(!lettura) {
                var votoEsame = {
                    Name: this.esame.Name +' - '+ this.$store.state.userprofile.Name,
                    Docente__c : this.$store.state.userprofile.Id,
                    Esame_Individuale__c : this.esame.Id,
                    Piatto__c : idPiatto,
                    Tipo__c : indicatore,
                    Voto__c : Number(voto),
                    Candidato__c : this.esame.Opportunita__r.AccountId
                }
                console.log('votoEsame',votoEsame)
                if(this.mTipoVoto.has(votoEsame.Tipo__c + votoEsame.Piatto__c)) votoEsame['Id'] = this.mTipoVoto.get(votoEsame.Tipo__c + votoEsame.Piatto__c)
 
                if(votoEsame.Voto__c < 18) votoEsame['Commento__c'] = commento
                else votoEsame['Commento__c'] = ''
                if(this.$store.state.nomeGiurato != '') {
                    votoEsame.Name = this.esame.Name +' - '+ this.$store.state.nomeGiurato
                    votoEsame['Giurato__c'] = this.$store.state.nomeGiurato
                }
 
                this.$store.dispatch('saveVotoEsame', {votoEsame: votoEsame})
                .then(resp => {
                    console.log('saveVotoEsame resp',resp)
                    this.mTipoVoto.set(resp.Tipo__c + resp.Piatto__c, resp.Id)
                })

                if(statoPiatto != 'Valutato') {
                    var data = { Id: idPiatto, Stato__c: 'Valutato' }
                    this.$store.dispatch('updateStatoPiatto', data)
                    .then( resp => { console.log(resp) })
                }
            }
 
            this.calcolaMediaTotale()
        },
        saveVotiIndicatoriEsame({indicatore, voto, commento, lettura}) {
            this.esame.indicatoriEsame[indicatore] = Number(voto)
            this.esame.commentiIndicatoriEsame[indicatore] = commento
 
            this.mediaIndicatoriEsame = this.calcolaVotoFinale(this.esame.indicatoriEsame, 'Esame')
 
            if(!lettura) {
                var votoEsame = {
                    Name: this.esame.Name +' - '+ this.$store.state.userprofile.Name,
                    Docente__c : this.$store.state.userprofile.Id,
                    Esame_Individuale__c : this.esame.Id,
                    Tipo__c : indicatore,
                    Voto__c : Number(voto)
                }
 
                if(this.mTipoVoto.has(votoEsame.Tipo__c)) votoEsame['Id'] = this.mTipoVoto.get(votoEsame.Tipo__c)
 
                if(votoEsame.Voto__c < 18) votoEsame['Commento__c'] = commento
                else votoEsame['Commento__c'] = ''
                if(this.$store.state.nomeGiurato != '') {
                    votoEsame.Name = this.esame.Name +' - '+ this.$store.state.nomeGiurato
                    votoEsame['Giurato__c'] = this.$store.state.nomeGiurato
                }
 
                this.$store.dispatch('saveVotoEsame', {votoEsame: votoEsame})
                .then(resp => {
                    console.log('saveVotoEsame resp',resp)
                    this.mTipoVoto.set(resp.Tipo__c, resp.Id)
                })
            }
 
            this.calcolaMediaTotale()
        },
        calcolaMediaTotale() {
            var listaMedieEsame = []
            for(var piatto of this.piatti) {
                listaMedieEsame.push(piatto.media)
            }
            this.esame.mediaTotale = math.round(math.mean(listaMedieEsame),2) + this.esame.mediaIndicatoriEsame
            this.mediaTotale = this.esame.mediaTotale
        },
        calcolaVotoFinale(voti, tipo) {
            console.log('--> calcolaVotoFinale', voti)
            var listaVoti = []
            var punti = 0
            for(var i of this.indicatori) {
                if(i.Tipo__c == tipo) {
                    var voto = voti[i.Descrizione__c]
                    if(i.Calcolo_valutazione__c == 'Media Aritmetica') {
                        if(voto && voto >= 0) listaVoti.push(voto)
                        else listaVoti.push(0)
                    }
                    if(i.Calcolo_valutazione__c == 'Punti aggiuntivi') punti += voto
                }
            }
            if(listaVoti.length > 0 && tipo == 'Piatto') return (math.round(math.mean(listaVoti),2)) + punti
            
            if(punti && tipo == 'Esame') this.punti = punti
            if(listaVoti.length > 0 && tipo == 'Esame') return math.round(math.mean(listaVoti),2)
            
            return 0
        }
    }
 }
 </script>