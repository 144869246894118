
<template>
    <div class="my-2 ">
        <div class="row bgAlma text-white rounded-top">
            <div class="col-10"><b>{{'#'+numerazioneAllievo +' '+ nome}}</b></div>
            <div class="col-2 text-end">Tav. {{ numTavolo }}</div>
        </div>

        <!-- RIGHE -->
        <div v-if="layout1">
            <div class="row border py-1" :class="getClass(piatto)" v-for="piatto in piatti" v-bind:key="piatto.id" @click="$emit('selezionaPiatto', { piatto: piatto, tavolo: numTavolo })">
                <div class="col-10">
                    <p v-if="!piatto.Stato__c" class="mb-0"><u>{{piatto.Tipo_Piatto__c}}: <i>"{{piatto.Name}}"</i></u></p>
                    <p v-else class="mb-0">{{piatto.Tipo_Piatto__c}}: <i>"{{piatto.Name}}"</i></p>
                </div>
                <div v-if="piatto.Ordine_di_uscita__c" class="col-2 text-end">#{{ piatto.Ordine_di_uscita__c}}</div>
            </div>
        </div>

        <!-- BLOCCHI -->
        <div v-if="!layout1" class="row">
            <div class="col border p-2 rounded-bottom" :class="getClass(piatto)" v-for="piatto in piatti" v-bind:key="piatto.id">
                <p class="mb-0"><b>{{piatto.Tipo_Piatto__c}}</b>: {{piatto.Name}}</p>
            </div>
        </div>
        
    </div>
    
</template>


<script>

 
export default {
    name: 'studenteUscitaPiatti',
    components: {
    },
    props: {
        nome: String,
        numTavolo: String,
        piatti: Array,
        layout1: Boolean,
        numerazioneAllievo: Number
    },
    data() {
        return {}
    },
    computed: {},
    mounted () {},
    methods: {
        getClass(piatto){
            if(piatto.Stato__c == 'Uscito') return 'bg-warning bg-opacity-50' // giallo
            if(piatto.Stato__c == 'Valutato') return 'bg-secondary bg-opacity-50' // grigio
            return ''
        }
    }
}
</script>