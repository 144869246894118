<template>
    <!-- <div id="pdf-viewer" class="d-none d-md-block border px-0" style="height:75vh;overflow-y:scroll;"></div> -->
    <div id="pdf-viewer" class="border px-0" style="height:75vh;overflow-y:scroll;"></div>
</template>
  
<script>
    import pdfjsLib from "pdfjs-dist/legacy/build/pdf";
    import "pdfjs-dist/web/pdf_viewer.css";
    
    export default {
        name: "PdfViewer",
        props: {
            idFile:     String,
            // fileBody:   String,
        },
        data() {
            return {
                pageNumber: 1,
                pages:      [],
                pdf:        Object,
            }
        },
        mounted() {},
        watch: {
            idFile: function() {
                this.showPdf()
            }
        },
        methods: {
            async showPdf() {
                var self = this
                console.log('idFile in PdfViewer', this.idFile)
                let resp = await this.$store.dispatch('getAttachment', {idFile: this.idFile})
                console.log('body',resp)

                if(resp != 'Niente file') {
                    this.$emit('fileBody',resp)
                    let body = atob(resp)
                
                    pdfjsLib.getDocument({data: body})
                    .then(function(pdfStudente) {
                        console.log('pdfStudente',pdfStudente)
                        self.pdf = pdfStudente
                        console.log('PDF loaded');

                        self.pageNumber = 1; // Fetch the first page
                        if (self.pageNumber <= self.pdf.numPages) self.getPage();
                    }).catch(function(reason) {
                        console.error(reason);
                    });
                }

            },
            getPage() {
                var self = this
                self.pdf.getPage(self.pageNumber)
                .then(function(page) {

                    var scale = 1;
                    var viewport = page.getViewport(scale);
                    // console.log('viewport',viewport)

                    // Prepare canvas using PDF page dimensions

                    // var canvas = document.getElementById('the-canvas');

                    var canvas = document.createElement("canvas");
                    canvas.className = 'border px-0'

                    var context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    // console.log('renderContext',renderContext)

                    page.render(renderContext)
                    .then(function() {
                        document.getElementById("pdf-viewer").appendChild(canvas);
                        // store compressed image data in array

                        // self.pages.push(canvas.toDataURL());
                        // console.log('Page loaded');

                        if (self.pageNumber < self.pdf.numPages) {
                            self.pageNumber++;
                            self.getPage();        // get next page
                        } else {
                            console.log('Caricate '+ self.pageNumber + ' pagine')
                        }
                    })
                })
            },
        }
    };
</script>