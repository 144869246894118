<template>
    <div v-if="$store.getters.logged">

        <div class="mx-4" style="margin-bottom: 5rem">

            <div class="row my-3 align-items-center">
                <div class="col text-end">
                    <label>Seleziona Gruppo:</label>
                </div>
                <div class="col">
                    <!-- <label>Seleziona Gruppo:</label> -->
                    <select class="form-select rounded-pill" v-model="gruppo">
                        <option value="" selected></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                    </select>
                </div>
                <div class="col">
                    <button type="button" @click="getSlotsAndPiatti()">Aggiorna</button>
                </div>
            </div>

            <slotUscitaPiatti
                v-for="slot in slots"
                v-bind:key="slot.id"
                :nomeSlot="slot.nome"
                :tesi="slot.tesi"
                :layout1="layout1"
                @selezionaPiatto="selezionaPiatto($event)"
            ></slotUscitaPiatti>
        </div>

        <modal :show="showConferma" :title="titleModal" :buttons="buttons" @click="manageModal($event)">
            <p>{{ piattoConferma.Tipo_Piatto__c }}: <i>"{{ piattoConferma.Name }}"</i></p>
        </modal>
    </div>
</template>

<script>
import slotUscitaPiatti from "../components/slotUscitaPiatti.vue";
import modal from "../components/modal.vue";

export default {
    name: "UscitaPiatti",
    components: {
        slotUscitaPiatti,
        modal,
    },
    data() {
        return {
            layout1: true,
            slots: [],

            showConferma: false,
            piattoConferma: {},
            tavoloConferma: '',
            titleModal: '',

            allPiatti: new Map(),
            mUltimoPiattoTavolo: new Map(),

            gruppo: '',
        };
    },
    computed: {
        buttons() {
            return ["Conferma", "Annulla"];
        },
    },
    created() {
        // this.getSlotsAndPiatti()
    },
    methods: {
        getSlotsAndPiatti() {
            this.$store.dispatch("getSlots", this.gruppo)
            .then( resp => {
                this.allPiatti = new Map()
                this.slots = []

                for(var r of Object.keys(resp)) {
                    var slot = {
                        id: r,
                        nome: 'Turno ' + r,
                        tesi: resp[r]
                    }
                    this.slots.push(slot);

                    resp[r].forEach(t => {
                        t.Piatti.forEach(p => {
                            if(this.allPiatti.has(t.tavolo)) this.allPiatti.get(t.tavolo).push(p)
                            else this.allPiatti.set(t.tavolo, [p])
                        })
                    })
                }
            })
        },
        numUltimoPiattoTav(tavolo) {
            return this.allPiatti.get(tavolo).filter(p => p.Ordine_di_uscita__c != null).length
        },
        selezionaPiatto({ piatto, tavolo }) {
            if(piatto.Stato__c != 'Valutato') {
                console.log('--> selezionaPiatto in UscitaPiatti.vue')
                console.log(piatto, tavolo) 

                if(piatto.Stato__c == 'Non Uscito' || piatto.Ordine_di_uscita__c == this.numUltimoPiattoTav(tavolo)) {
                    this.showConferma = true
                    this.piattoConferma = piatto
                    this.tavoloConferma = tavolo

                    if(piatto.Stato__c == 'Non Uscito') this.titleModal = 'Conferma l\'uscita del piatto'
                    if(piatto.Stato__c == 'Uscito') this.titleModal = 'Rimetti in coda il piatto'
                } else console.log('Non è possibile selezionare il piatto')
            }
        },
        manageModal(e) {
            console.log(e)

            var button = this.buttons[e]
            if(button == 'Conferma') {

                this.piattoConferma.Stato__c = this.piattoConferma.Stato__c == 'Non Uscito' ? 'Uscito' : 'Non Uscito'

                this.piattoConferma.Ordine_di_uscita__c = !this.piattoConferma.Ordine_di_uscita__c ? (this.numUltimoPiattoTav(this.tavoloConferma)+1) : null
                
                this.piattoConferma.Data_Ora_Uscita_Piatto__c = !this.piattoConferma.Data_Ora_Uscita_Piatto__c ? Date.now() : null


                console.log(this.piattoConferma)
                this.$store.dispatch('updateStatoPiatto', this.piattoConferma)
                .then( resp => {
                    console.log(resp)
                    this.mUltimoPiattoTavolo.set(this.tavoloConferma, this.piattoConferma)
                    this.getSlotsAndPiatti()
                })
            }

            this.showConferma = false
        }
    },
};
</script>
