<template>
<!-- nuova -->

<nav class="navbar navbar-light sticky-top bg-light shadow mb-4">
  <div class="container-fluid">

    <div class="col-auto">
      <a v-if="$store.getters.logged" class="navbar-brand" @click="closeMenu" href='/#/home'><img class="img-fluid" src="../../public/img/alma-logo-it_4ea26a94.svg" alt="" srcset=""></a>
      <a v-else class="navbar-brand" @click="closeMenu" href='/#/login'><img class="img-fluid" src="../../public/img/alma-logo-it_4ea26a94.svg" alt="" srcset=""></a>
    </div>

    <div v-if="$store.getters.logged && $store.state.userprofile.Name && !$store.state.userprofile.Name.includes('Giurato')" class="col">
        <div class="col-6 mx-auto">
          <p class="fw-bold mb-0 text-center text-white rounded-3 bgAlma">
            <i class="bi bi-person-fill text-white"></i> {{ $store.state.userprofile.Name }}
            <br>{{ pageName }} - {{ oggi }}
          </p>
        </div>
    </div>

    <!-- <div class="d-flex justify-content-end me-3">
        <i class="bi bi-bell-fill fs-2 text pe-3"></i>
        <button class="navbar-toggler border-0" @click="openMenu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="fs-2 text bi bi-list"></span>
        </button>
    </div> -->
    
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header justify-content-end pe-4 pt-4">
        <!-- <button type="button" class="btn-close btn-close-white pe-4 pt-4 btn-lg opacity-100" @click="closeMenu" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
      </div>

      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">

          <li class="nav-item" v-for="option in $store.getters.abilitazioni" v-bind:key="option.title" v-bind:value="option">
            <a class="nav-link active text-center text-light fs-5" aria-current="page" 
              @click="closeMenu" v-bind:href="option.link" 
            >{{ option.title }}</a>
          </li>

          <li class="nav-item">
              <button type="button" class="btn text-light nav-link active text-center text-light fs-5 mx-auto" aria-current="page" @click="beforerun">
                  Attiva notifiche
              </button>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
  export default {
    name: 'NavBar',
    data() {
      return {
        oggi: new Date().toLocaleDateString(),
      };
    },
    computed: {
      pageName() {
        var path = this.$route.path
        var page = this.$store.getters.abilitazioni.filter(page => page.link.search(path) != -1)
        return page.length > 0 ? page[0].title : 'Home'
      }
    },
    mounted: function() {
      this.checkNotifiche();
      Notification.requestPermission().then(function(permission) {
        if (permission !== 'granted') {
          console.log(permission)
          throw new Error('Permission not granted for Notification')
        }
      })
    },
    methods: {
        closeMenu() {
          const menuCanvas = document.querySelector('#offcanvasNavbar');
          menuCanvas.className = 'offcanvas offcanvas-end'
          menuCanvas.style = 'visibility: hidden;'
        },
        toggleMenu() {
            const menuCanvas = document.querySelector('#offcanvasNavbar');
            menuCanvas.className += ' show'
            menuCanvas.style = 'visibility: visible;'
        },

        checkNotifiche: function(){
        if('serviceWorker' in navigator){
          console.log('serviceWorker OK');
        }else{
          console.log('serviceWorker BAD');
        }
      },
      beforerun: async function() {
        console.log(navigator);

          if ('serviceWorker' in navigator || !navigator.serviceWorker) {
            console.log('Registering service worker');

            if (confirm('notifiche?')) {
                this.run().catch(error => {
                    console.error(error)
                    // alert(JSON.stringify(error));
                });
              }
          } else {
              alert('no serviceworker??')
          }
      },

      run: async function() {
        console.log('Registering service worker', navigator.serviceWorker);
        const registration = await navigator.serviceWorker.register('/worker.js', {scope: '/'});
        console.log('Registered service worker');

        console.log('Current subs');
        console.log('registration:',registration);
        console.log('pushmanager:',registration.pushManager);
        console.log('getSubscription:',registration.pushManager.getSubscription());

        let subscription=await registration.pushManager.getSubscription();
        console.log(subscription);

        if (subscription) {
          // console.log('unsubscribe');
          // let successful=await subs.unsubscribe();
          // console.log(successful);
          console.log('già resigstrato');   // in questo punto saprei già se il dispositivo è iscritto ma per sicurezza il controllo viene fatto anche nel backend
        }else{
          console.log('Registering push');
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(this.publicVapidKey),
          });
        }

        console.log('Sending push', subscription);
        this.$store.dispatch('subscribePush', {details: subscription, AccountId: this.$store.state.userprofile.Id});

        console.log('Sent push');
      },

      urlBase64ToUint8Array: function(base64String) {
          console.log('Entro', base64String);
          var padding = '='.repeat((4 - base64String.length % 4) % 4);
          console.log('Dopo entro');
          var base64 = (base64String + padding)
              .replace(/-/g, '+')
              .replace(/_/g, '/');

          var rawData = window.atob(base64);
          var outputArray = new Uint8Array(rawData.length);

          for (var i = 0; i < rawData.length; ++i) {
              outputArray[i] = rawData.charCodeAt(i);
          }
          return outputArray;
      }
    }
  }
</script>