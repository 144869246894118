<template>
  <div v-if="$store.getters.logged" class="container mt-4">
    <h1 v-if="students.length > 0 && students[0].nomeEvento" class="text-center mb-4 fw-bold">{{ students[0].nomeEvento }}</h1>

    <div class="row my-3 align-items-center">
        <div class="col text-end">
            <label>Seleziona Gruppo:</label>
        </div>
        <div class="col">
            <!-- <label>Seleziona Gruppo:</label> -->
            <select class="form-select rounded-pill" v-model="gruppo">
                <option value="" selected></option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
            </select>
        </div>
        <div class="col">
            <button type="button" @click="getSlotsAndPiatti()">Aggiorna</button>
        </div>
        <!-- <div class="col">
            <div class="col">Ordina per: </div>
            <button type="button" @click="elencoStudenti = !elencoStudenti">{{ !elencoStudenti ? 'Studenti' : 'Tavoli' }}</button>
        </div> -->
    </div>

    <table v-if="students.length" class="table table-striped table-bordered text-center table-responsive table-nowrap">
      <thead>
        <tr>
          <th class="" @click="sortByNum"># <i v-if="elencoStudenti" class="bi bi-caret-up-fill"></i></th>
          <th class="">Nome e Cognome </th>
          <th class="" @click="sortByTav">Tav <i v-if="!elencoStudenti" class="bi bi-caret-up-fill"></i></th>
          <th v-for="(p, index) in students[0].Piatti" :key="index">
              <span>Piatto n.{{ index+1 }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, index) in students" :key="index">
          <td>{{ student.numerazioneAllievo }}</td>
          <td>{{ student.Candidato }}</td>
          <td>{{ student.tavolo }}</td>
          <td class="" v-for="(piatto, piattoIndex) in student.Piatti" :key="piattoIndex">
              <div class="d-flex flex-row">
                  <div class="w-100">
                      <div >
                          <div><span>{{ piatto.Tipo_Piatto__c }}:</span></div>
                          <span><i>"{{ piatto.Name }}"</i></span>
                      </div>
                      <div class="">
                          <button style="width: 10vw" class="mx-auto align-bottom" :class="{'btn-warning' : piatto.Stato__c != 'Non Uscito'}"
                            :disabled="piatto.Stato__c == 'Valutato'"
                            @click="selezionaPiatto(piatto, student.tavolo)"
                          >
                            {{ piatto.Stato__c == 'Non Uscito' ? 'Conferma' : 'Confermato' }}
                          </button>
                      </div>
                  </div>
                  <div class="row align-items-center ms-3 flex-shrink-1">
                    <span class="jumbotron"><i>{{ piatto.Ordine_di_uscita__c ? '#'+piatto.Ordine_di_uscita__c : '' }}</i></span>
                  </div>
              </div>
          </td>
        </tr>
      </tbody>
    </table>
    <modal :show="showConferma" :title="titleModal" :buttons="buttons" @click="manageModal($event)">
        <p>{{ piattoConferma.Tipo_Piatto__c }}: <i>"{{ piattoConferma.Name }}"</i></p>
    </modal>
  </div>
</template>

<script>
import modal from "../components/modal.vue";

export default {
  name: "Servizio",
  components: {
      modal,
  },
  data() {
    return {
      elencoStudenti: true,
      gruppo: '',
      showConferma: false,
      piattoConferma: {},
      tavoloConferma: '',
      titleModal: '',
      students: [],
      allPiatti: new Map(),
    };
  },
  mounted () {
    // this.students.sort((a, b) => a.numerazioneAllievo - b.numerazioneAllievo);
  },
  computed: {
      buttons() {
          return ["Conferma", "Annulla"];
      },
  },
  methods: {
    getSlotsAndPiatti() {
        console.log('getSlotsAndPiatti');
        this.students = [];
        this.$store.dispatch("getSlots", this.gruppo)
        .then( resp => {
            this.allPiatti = new Map()
            console.log(resp);
            for(let slot in resp){
                for(let student of resp[slot]){
                    this.students.push(student);
                    for(let piatto of student.Piatti){
                        if(this.allPiatti.has(student.tavolo)) this.allPiatti.get(student.tavolo).push(piatto)
                        else this.allPiatti.set(student.tavolo, [piatto])
                    }
                }
            }
            if(this.elencoStudenti) this.sortByNum();
            else this.sortByTav();
            console.log(this.students)
        })
    },
    numUltimoPiattoTav(tavolo) {
        return this.allPiatti.get(tavolo).filter(p => p.Ordine_di_uscita__c != null).length
    },
    selezionaPiatto(piatto, tavolo) {
      console.log('piatto e tavolo', piatto, tavolo)
      console.log(this.numUltimoPiattoTav(tavolo))
      if(piatto.Stato__c == 'Non Uscito' || piatto.Ordine_di_uscita__c == this.numUltimoPiattoTav(tavolo)) {
          console.log('allPiatti', this.allPiatti);
          this.showConferma = true;
          this.piattoConferma = piatto;
          this.tavoloConferma = tavolo
        
          if(piatto.Stato__c == 'Non Uscito') this.titleModal = 'Conferma l\'uscita del piatto'
          if(piatto.Stato__c == 'Uscito') this.titleModal = 'Rimetti in coda il piatto'
      }
    },
    manageModal(e) {
        console.log(e)

        var button = this.buttons[e]
        if(button == 'Conferma') {

            this.piattoConferma.Stato__c = this.piattoConferma.Stato__c == 'Non Uscito' ? 'Uscito' : 'Non Uscito';

            this.piattoConferma.Ordine_di_uscita__c = !this.piattoConferma.Ordine_di_uscita__c ? (this.numUltimoPiattoTav(this.tavoloConferma)+1) : null
                
            this.piattoConferma.Data_Ora_Uscita_Piatto__c = !this.piattoConferma.Data_Ora_Uscita_Piatto__c ? Date.now() : null

            console.log('piattoConferma',this.piattoConferma);
            this.$store.dispatch('updateStatoPiatto', this.piattoConferma)
            .then( resp => {
                console.log(resp);
                this.getSlotsAndPiatti();
            })
        }

        this.showConferma = false
    },
    sortByNum() {
      this.students.sort((a, b) => a.numerazioneAllievo - b.numerazioneAllievo);
      this.elencoStudenti = true;
    },
    sortByTav() {
      this.students.sort((a, b) => a.tavolo - b.tavolo);
      this.elencoStudenti = false;
    },
  },
};
</script>