<template>
    <table class="table table-bordered border-secondary">
        <tbody>
            <tr><th class="bgAlma text-white" scope="col">{{ indicatore.Ordinatore__c}}. {{ indicatore.Descrizione__c }}</th></tr>
            <tr>
                <td>
                    <div>
                        <div class="col" :class="{'pe-0' : voto < 0 }">
                            <select class="form-select rounded-pill py-0" v-model="voto"
                            v-on:change="$emit('saveVotiIndicatoriEsame', { indicatore: indicatore.Descrizione__c, voto: voto})"
                            :class="{'bg-danger bg-opacity-25 border-danger text-danger' : voto < 0 && !indicatore.Opzionale__c}">
                                <option disabled selected value="-1">{{ indicatore.Descrizione__c }}</option>
                                <option v-for="v in voti_in(indicatore.Valutazione_in__c)" v-bind:key="v">{{ v }}</option>
                            </select>
                        </div>
                    </div>
                    <input v-if="voto > -1 && voto < 18 && !indicatore.Opzionale__c" placeholder="Note"
                        class="mt-2 border" :class="{'border-danger bg-danger bg-opacity-25' : !commento}"
                        v-model="commento" type="text"
                        v-on:change="$emit('saveVotiIndicatoriEsame', {indicatore: indicatore.Descrizione__c, voto: voto, commento: commento})">
                </td>
            </tr>
        </tbody>
    </table>
</template>
 
 
<script>
export default {
    Descrizione__c: 'votoEsame',
    components: {},
    props: {
        indicatore: Object,
        voto: Number,
        commento: String,
    },
    data() {
        return {}
    },
    computed: {},
    mounted () {},
    created() {},
    methods: {
        voti_in(num) {
            var elencoVoti = []
            for(var i = num; i >= 0; i--) {
                elencoVoti.push(i)
            }
            return elencoVoti
        },
    }
}
</script>