import Vue from 'vue'
import Vuex from 'vuex'
import apiclient from '@/apiclient.js'
import jwt from 'jsonwebtoken'
// import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";
// var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [
  //   createPersistedState({
  //     storage: {
  //       getItem: (key) => ls.get(key),
  //       setItem: (key, value) => ls.set(key, value),
  //       removeItem: (key) => ls.remove(key),
  //     },
  //   }),
  // ],
  state: {
    nomeGiurato: '',
    tavoloGiurato: '',
    // lingua: 'it',
    routerlock: false,
    mainmenu: [],
    durty: false,
    userprofile: {},

    idOppty:'',
    idOpptyTesi:'',
    opptyCorso:'',
    idHousing:'',
    opptyHousing:'',
    opptyHousingFatt:{},
    opptyPrenot:{},
    housing:[],
    templatesAbbigliamento: {},

    connection: {
      commit: {},
      username: '',
      token: '',
      userdata: '',
    },

    campi: {},
    prodotti: {},

    casi: {},
    casicat: [],

    vouchercode: '',

    // ecommerce
    imgEcommerce: {},
    categoriaEcommerce: [],
    carrelloEcommerce: [],
    totaleEcommerce: [],

    tesi: {},
    piatto: {},
    condizione: {},

    corsi: {},
    materiali: {},
    candidatura: {},
    documenti: {},
    pagamenti: {},
    emailRecovery: '',
    tkn: '',
    newpwd: '',
    newpwdconf: '',
    fakelogin: false,
    fakeloginstatus: false,
    blocked: false,
    registrationDenied: false,
    productcode: '',
    course:'',
    edition: '',
    purchased: false,
  },
  getters: {
    logged: function(state) {
      if (state.fakelogin) return state.fakeloginstatus;
      return (state.connection.userdata && state.userprofile.Username__pc?true:false)
    },
    housing: function(state) {
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            if (o.IsActiveHousing__c) f=true;
          });
        }
      }
      return f;
    },
    candidato: function(state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Candidato');
    },
    studente: function(state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Studente');
    },
    dipendente: function (state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Dipendente')
    },
    isEcommerceVisible: function (state) {
      if (state.userprofile)
        if (state.userprofile.Id === '0010Q00001D4sDFQAZ' || state.userprofile.Id === '0015J000008bYR2QAM' || state.userprofile.Id === '0010Q00001X0AmtQAF' || state.userprofile.Id === '0015J000005xtNpQAI')
          return true

      // 0010Q00001D4sDFQAZ => test modea     (sandbox)
      // 0010Q00001X0AmtQAF => account dummy  (sandbox)
      // 0015J000008bYR2QAM => gabriele prova (prod)
      // 0015J000005xtNpQAI => ilaria         (prod)

      return false
    },
    hyperplan: function(state) {
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            if (o.isActiveHyperplanning__c) f=true;
          });
        }
      }
      return f;
    },
    stage: function(state) {
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            if (o.IsActiveStage__c) f=true;
          });
        }
      }
      return f;
    },
    enablePresenze: function(state) {
      var f = false;
      if(state.userprofile) {
        // console.log('QUI-->', state.userprofile)
        if(state.userprofile.Abilitazioni_Webapp__pc){
          if(state.userprofile.Abilitazioni_Webapp__pc.includes('Presenze')){
            f = true;
          }
        }
      }
      return f;
    },
    abilitazioni: function(state) {
      if(state.userprofile) {
        if(state.userprofile.Abilitazioni_Webapp__pc){
          var abilitazioni = state.userprofile.Abilitazioni_Webapp__pc.split(';') 
          var cards = []

          if(abilitazioni.includes('Presenze')) {
            cards.push( {
              title: 'Presenze',
              link: '/#/Presenze',
              icon: 'bi bi-calendar-event'
            } );
          }
      
          if(abilitazioni.includes('Giudizio Tesi')) {
            cards.push( {
              title: 'Giudizio Tesi',
              link: '/#/Tesi',
              icon: 'bi bi-file-earmark-check'
            } );
          }
      
          if(abilitazioni.includes('Valutazioni Esami')) {
            cards.push( {
              title: 'Esami',
              link: '/#/Esami',
              icon: 'bi bi-file-earmark-check'
            } );
          }

          if(abilitazioni.includes('Apertura Porte')) {
            cards.push( {
              title: 'Apri Porte',
              link: '/#/Porte',
              icon: 'bi bi-door-open'
            } );
          }

          if(abilitazioni.includes('Ecommerce Interno')) {
            var jwtpayload = jwt.sign( localStorage.getItem('user'), apiclient.secret );
            cards.push( {
              title: 'Shop',
              link: 'http://devalma.salespaddy.com/#/ecommerce?user='+ jwtpayload,
              icon: 'bi bi-cart',
            } );
          }

          if(abilitazioni.includes('Uscita Piatti')) {
            cards.push( {
              title: 'Uscita Piatti',
              link: '/#/UscitaPiatti',
              icon: 'bi bi-ui-checks-grid'
            } );
          }

          if(abilitazioni.includes('Servizio')){
            cards.push( {
              title: 'Servizio',
              link: '/#/Servizio',
              icon: 'bi bi-clipboard2-check'
            } );
          }
          return cards 
        }
      }
      return [];
    },
    matricola: function(state) {
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            // console.log(o.Name+' '+o.IsActiveTesi__c);
            if (o.IsActiveTesi__c) f=true;
          });
        }
      }
      return f;
    },
    tesi: function(state) {
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            console.log(o.Name+' tesi='+o.IsActiveTesi__c);
            if (o.IsActiveTesi__c){
               f=true;
               state.idOpptyTesi = o.Id;
            }
          });
        }
      }
      return f;
    },
    elearn: function(state) {
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            console.log(o.Name+' elearning='+o.isActiveElearning__c);
            if (o.isActiveElearning__c) f=true;
          });
        }
      }
      return f;
    },
    diplomato: function(state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Diplomato');
    },
    prenotazioni: function(state){
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            console.log(o.Name+' prenot='+o.IsActivePrenotazione__c);
            if (o.IsActivePrenotazione__c){
               f=true;
               state.opptyPrenot = o;
            }
          });
        }
      }
      return f;
    }
  },
  mutations: {
    SET_NOMEGIURATO: function(state, payload) {
      state.nomeGiurato=payload;
      localStorage.setItem('nomeGiurato', payload);
    },
    SET_TAVOLOGIURATO: function(state, payload) {
      state.tavoloGiurato=payload; 
      localStorage.setItem('tavoloGiurato', payload);
    },
    
    // SET_LINGUA: function(state, payload) { state.lingua=payload; },
    SET_ROUTERLOCK: function(state, payload) { state.routerlock=payload; },
    SET_CAMPI: function(state, payload) { state.campi=payload; },
    SET_PRODOTTI: function(state, payload) { state.prodotti=payload; },

    SET_FAKELOGIN: function(state, payload) { state.fakelogin=true; state.fakeloginstatus=payload; },

    SET_CASI: function(state, payload) { state.casi=payload; },
    SET_CATEGORIE_CASI: function(state, payload) { state.casicat = payload },

    SET_TESI: function(state, payload) { state.tesi=payload; },
    SET_CONDIZIONE: function(state, payload) { state.condizione=payload; },
    SET_PIATTO: function(state, payload) { state.piatto=payload; },

    SET_LIBRI: function(state, payload) { state.libri=payload; },

    SET_CORSI: function(state, payload) { state.corsi=payload; },
    SET_MATERIALI: function(state, payload) { state.materiali=payload; },

    SET_HOUSING: function(state, payload) { state.housing=payload; },
    SET_CANDIDATURA: function(state, payload) { state.candidatura=payload; },
    SET_DOCUMENTI: function(state, payload) { state.documenti=payload; },
    SET_PAGAMENTI: function(state, payload) { state.pagamenti=payload; },

    SET_PURCHASED: function(state, payload) { state.purchased = payload; },
    SET_MAINMENU: function(state, payload) { state.mainmenu=payload; },

    // connessione crittata con il backend
    SET_CONNECTION: function(state, payload) { state.connection = payload; },
    SET_BLOCKED: function(state, payload) { state.blocked = payload; },
    SET_REG_DENIED: function(state, payload) { state.registrationDenied = payload; },
    // profilo utente
    SET_USER_PROFILE: function(state, payload) { state.userprofile = payload; state.durty = false; },
    SET_USERPROFILE_DURTY: function(state, payload) { state.durty = payload; },
    SET_USERPROFILE_FIELD: function(state, payload) { state.userprofile[payload.name] = payload.val; },
    //update campi opportunità
    SET_CANDIDATURA_FIELD: function(state, payload) { state.candidatura[0][payload.name] = payload.val; },
    SET_ABBIGLIAMENTO_CANDIDATURA: function(state, payload) { state.templatesAbbigliamento = payload; },
    SET_CATEGORIA_ECOMMERCE: function(state, payload) { state.categoriaEcommerce = payload; },
    // retrocompabilita' con il precedente portale: localStorage e cookies
    RESET_USER_DATA:function(state) {
      console.log('RESET_USER_DATA');
      localStorage.removeItem('user');
      localStorage.removeItem('userprofile');
      localStorage.removeItem('userdata');
      localStorage.removeItem('tavoloGiurato');
      localStorage.removeItem('nomeGiurato');
      localStorage.removeItem('nome');
      localStorage.removeItem('token');
      //localStorage.clear();
      state.connection.username='';
      state.connection.userdata='';
      state.connection.status='';
      state.connection.token='';
      state.userprofile={};
      state.carrelloEcommerce=[];
      state.userprofile={}
    },
    RESET_GIURATO_DATA:function() {
      console.log('RESET_GIURATO_DATA');
      localStorage.removeItem('tavoloGiurato');
      localStorage.removeItem('nomeGiurato');
    },
    GET_GIURATO:function(state) {
      console.log('GET_GIURATO')
      state.nomeGiurato = localStorage.getItem('nomeGiurato')
      state.tavoloGiurato = localStorage.getItem('tavoloGiurato')
      state.connection.userdata = localStorage.getItem('userdata')
      state.connection.token = localStorage.getItem('token')
    },
    GET_USER_DATA:function(state) {
      console.log('GET_USER_DATA');
      var userData=localStorage.getItem('user');
      console.log('userData',userData);
      if (userData) {
        var ud=JSON.parse(userData);
        state.connection.username=ud.username;
        state.connection.userdata=ud.token;
        state.connection.status=ud.status;
        state.login=true;

        if (window.OpenReplay && window.OpenReplay.setUserID) {
          console.log('setuserid');
          window.OpenReplay.setUserID(ud.username);
        }

        console.log('localStorage',localStorage)
        var jwtpayload = localStorage.getItem('userprofile');
        if (jwtpayload) {
          jwt.verify(jwtpayload, apiclient.secret, function(err, data) {
            if (err) {
              console.log('bad request: jwt broken');
              return;
            } else {
              localStorage.setItem('nome', data.Name);
              state.userprofile=data;
              console.log('state.userprofile', data)
              // if (state.userprofile.Opportunities) {
              //   console.log('Opportunities');
              //   console.log(state.userprofile.Opportunities);
              //   state.userprofile.Opportunities.records.forEach(function(o) {
              //     //console.log(o);
              //     if (o.IsActive__c && !o.IsActiveHousing__c && o.Name.indexOf('Housing')<0 ) {
              //       console.log('oppty');
              //       state.opptyCorso=o;
              //       state.idOppty=o.Id;
              //     }
              //     if (o.IsActiveHousing__c) {
              //       console.log('housing');
              //       state.opptyHousing=o;
              //       state.idHousing=o.Id;
              //     }
              //     if(o.Housing_compilazione_dati__c) {
              //       state.opptyHousingFatt = o;
              //     }
              //     if(state.opptyHousingFatt && o.IsActiveHousing__c){
              //       state.opptyHousingFatt=o;
              //     }
              //   });
              // }
            }
          });
        }
      }
    },
    SET_USER_DATA:function(state, userData) {
      console.log('SET_USER_DATA');

      localStorage.setItem('user', JSON.stringify(userData));

      if (window.OpenReplay && window.OpenReplay.setUserID) {
        console.log('setuserid', userData.username);
        window.OpenReplay.setUserID(userData.username);
      }

      var exp = new Date();
      var minutes = 1440;
      exp.setTime(exp.getTime() + (minutes * 60 * 1000));

      var myObject = {"almauser": userData.username, "token": userData.token};
      document.cookie = 'almauser='+ JSON.stringify(myObject) +';expires=' + exp + ';domain=scuolacucina.it;path=/' ;

      var numeroNotifiche=0;
      var myObject2 = {"numeronotifiche ": numeroNotifiche};
      document.cookie = 'almacart='+ JSON.stringify(myObject2) +';expires=' + exp + ';domain=scuolacucina.it;path=/' ;

      // console.log(document.cookie);
    },
    // ecommerce: carrello
    GET_CARRELLO: function(state){
      var userData=localStorage.getItem('carrello');
      if(userData){
        state.carrelloEcommerce = JSON.parse(userData);
        var tot = 0;
        state.carrelloEcommerce.forEach(function(d){
          tot+=parseInt(d.qty)*d.Prezzo__c;
        })
        state.totaleEcommerce = tot.toFixed(2);
      }
    },
    SET_CARRELLO:function(state, userData) {
      console.log('SET_CARRELLO');

      localStorage.setItem('carrello', JSON.stringify(userData));

      var exp = new Date();
      var minutes = 1440;
      exp.setTime(exp.getTime() + (minutes * 60 * 1000));

      document.cookie = 'almacarrello='+ JSON.stringify(userData) +';expires=' + exp + ';domain=scuolacucina.it;path=/' ;
      // console.log(document.cookie);
    },
    REMOVE_PROD_CARRELLO:function(state, userData) {
      console.log('REMOVE_PROD_CARRELLO');
      state.carrelloEcommerce.splice(userData,1);
      var tot = 0;
      state.carrelloEcommerce.forEach(function(d){
        tot+=parseInt(d.qty)*d.Prezzo__c;
      })
      state.totaleEcommerce = tot;
      localStorage.setItem('carrello', JSON.stringify(state.carrelloEcommerce));
    },
    RESET_CARRELLO:function(state) {
      console.log('RESET_CARRELLO');
      localStorage.removeItem('carrello');
      state.carrelloEcommerce=[];
    },
    // ecommerce: immagini
    SET_IMAGE: function(state, id, img) {
      console.log(`SET_IMAGE id: ${id}`)
      state.imgEcommerce.id = img
    }
  },
  actions: {
    getEvent: function(context){
      return new Promise(function(resolve){
        context.dispatch('loginbyid').then(function(){
          console.log("GET EVENT");
            //var self=this;
            console.log('USERNAME: ', context.state.connection.username);
            var data={callname:'getEvent', user: context.state.connection.username, username: context.state.connection.username, crypt: context.state.connection.userdata};
            apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
                console.log("EVENTI ==> ", resp);
                //self.corsi=resp;
                resolve(resp);
            });
        })
      })
    },
    saveApriPorta: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('saveApriPorta');
          var data={callname:'saveApriPorta', porta:payload.porta, crypt: context.state.connection.userdata};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('saveApriPorta:', resp);
            resolve(resp);
          });
        });
      })
    },
    getAttivita: function(context, lattivita){
      return new Promise(function(resolve){
        context.dispatch('loginbyid').then(function(){
          console.log("GET ATTIVITA");
            //var self=this;
            var data={callname:'getAttivita', attivita: lattivita, username: context.state.connection.username, crypt: context.state.connection.userdata};
            apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
                console.log("ATTIVITA ==> ", resp);
                //self.corsi=resp;
                resolve(resp);
            });
        })
      })
    },
    getPartecipanti: function(context, idevento){
      return new Promise(function(resolve){
        context.dispatch('loginbyid').then(function(){
          console.log('GET PARTECIPANTI');
          var data = {callname:'getPartecipanti', idevento, username: context.state.connection.username, crypt: context.state.connection.userdata};
          apiclient.getcandidatura(context.state.connection, data).then(function(resp){
            console.log('PARTECIPANTI: ',resp);
            resolve(resp);
          });
        })
      })
    },
    getCorso: function(context){
      return new Promise(function(resolve){
        context.dispatch('loginbyid').then(function() {
          console.log("GET CORSO");
            //var self=this;
            var data={callname:'getCorso', username: context.state.connection.username, crypt: context.state.connection.userdata};
            apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
                console.log("CORSI ==> ", resp);
                //self.corsi=resp;
                resolve(resp);
            });
        })
      })
    },
    getCorsoTesiActive: function(context){
      return new Promise(function(resolve){
        context.dispatch('loginbyid').then(function() {
          console.log("GET CORSO TESI ACTIVE");
            //var self=this;
            var data={callname:'getCorsoTesiActive', username: context.state.connection.username, crypt: context.state.connection.userdata};
            apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
                console.log("CORSI ==> ", resp);
                //self.corsi=resp;
                resolve(resp);
            });
        })
      })
    },
    getLibri: function(context) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          // compilo payload con gli id delle opportunità
          console.log('getLibri')
          var data = {
            username: context.state.connection.username,
            callname: 'getLibri',
            crypt: context.state.connection.userdata
          }
          apiclient.getcandidatura(context.state.connection, data).then(function(resp) {
            console.log('getLibri:', resp)
            context.commit('SET_LIBRI', resp)
            resolve(resp)
          })
        })
      })
    },
    setTesi: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('setTesi');
          console.log('context.state.tesi',context.state.tesi)
          var data={ username: context.state.connection.username, callname:'upsertTesi', crypt: context.state.connection.userdata, payload: context.state.tesi };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('setTesi:');
            console.log(resp)
            context.commit('SET_TESI', resp);
            resolve();
          });
        });
      })
    },
    getStudentiPerGiudizio: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getStudentiPerGiudizio');
          var data = {
            callname:'getStudentiPerGiudizio',
            edCorso:payload.edCorso,
            sezione: payload.sezione,
            idAccount: payload.idAccount,
            periodo: payload.periodo
          };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getStudentiPerGiudizio:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getGiudizi: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getGiudizi');
          var data={callname:'getGiudizi', idTesi:payload.idTesi};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getGiudizi:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    saveTesi: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('saveTesi');
          var data={callname:'saveTesi', tesi:payload.tesi};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('saveTesi:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getAttachment: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getAttachment');
          var data={callname:'getAttachment', idFile: payload.idFile, parentId: payload.parentId};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getAttachment:', resp);
            resolve(resp);
          });
        });
      })
    },
    saveGiudizio: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('saveGiudizio');
          console.log('context.state.connection',context.state.connection)
          var data={callname:'saveGiudizio', giudizio:payload.giudizio, info:payload.info};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('saveGiudizio:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getSlots: function(context, gruppo){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getSlots in index.js');
          apiclient.getcandidatura( context.state.connection, { callname:'getSlots', sezione: gruppo }).then( function(resp) {
            console.log('getSlots:',resp);
            resolve(resp);
          });
        });
      })
    },
    /* getPrenotazioniEsame: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getPrenotazioniEsame in index.js');
          var data = {
            callname:'getPrenotazioniEsame', 
            numTavolo: payload.numTavolo
          };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getPrenotazioniEsame:',resp);
            resolve(resp);
          });
        });
      })
    }, */
    getListaEsami: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getListaEsami in index.js');
          var data = { callname:'getListaEsami', numTavolo: payload.numTavolo };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getListaEsami:',resp);
            resolve(resp);
          });
        });
      })
    },
    getPiattiPerEsame: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getPiattiPerEsame in index.js');
          var data = {
            callname:'getPiattiPerEsame', 
            giornoEsame:payload.giornoEsame, 
            numTavolo: payload.numTavolo
          };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getPiattiPerEsame:',resp);
            resolve(resp);
          });
        });
      })
    },
    getCondizioniEsame: function(context, idCorso){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCondizioniEsame in index.js');
          var data = {
            callname:'getCondizioniEsame', 
            idCorso: idCorso
          };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getCondizioniEsame:',resp);
            resolve(resp);
          });
        });
      })
    },
    getQtaVoti: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getQtaVoti in index.js');
          var data = {
            callname:'getQtaVoti', 
            prenotazioni: payload.prenotazioni,
            idDocente: payload.idDocente, 
            nomeGiurato: payload.nomeGiurato
          };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getQtaVoti:',resp);
            resolve(resp);
          });
        });
      })
    },
    getInfoStage: function( context, payload ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getInfoStage');
          apiclient.getcandidatura( context.state.connection, { callname:'getInfoStage', opptyId: payload.opptyId })
          .then( function(resp) {
            console.log('getInfoStage resp:',resp)
            resolve(resp);
          });
        });
      })
    },
    getEsame: function(context, idPrenotazione){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getEsame in index.js');
          var data={callname:'getEsame', idPren: idPrenotazione, idDocente: context.state.userprofile.Id, nomeGiurato: context.state.nomeGiurato};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getEsame:',resp);
            resolve(resp);
          });
        });
      })
    },
    getPiattiStudente: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getPiattiStudente');
          var data={callname:'getPiattiStudente', idTesi:payload.idTesi};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getPiattiStudente:',resp);
            resolve(resp);
          });
        });
      })
    },
    saveVotoEsame: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('saveVotoEsame');
          console.log('context.state.connection',context.state.connection)
          var data={callname:'saveVotoEsame', votoEsame: payload.votoEsame};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('saveVotoEsame:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getTesi: function( context, tipo ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getTesi');
          var data={ username: context.state.connection.username, callname:'getTesi', crypt: context.state.connection.userdata, idOppty: context.state.idOpptyTesi,  };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getTesi in index.js:');
            console.log('resp',resp)
            console.log('tipo',tipo)
            if (resp.length>0) {
              for(var tesi of resp) {
                console.log('tesi.Tipo_Esame__c',tesi.Tipo_Esame__c)
                if(tipo == tesi.Tipo_Esame__c || (tipo == undefined && tesi.Tipo_Esame__c == null)) {
                  context.commit('SET_TESI', tesi);
                  break
                }
              }
              // context.commit('SET_TESI', resp[0]);
            
            } else
              context.commit('SET_TESI', {} );
            resolve();
          });
        });
      })
    },
    getPiatto: function( context, idPiatto ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getPiatto');
          var data={ username: context.state.connection.username, callname:'getPiatto', crypt: context.state.connection.userdata, idOppty: idPiatto };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getPiatto:');
            console.log(resp)
            context.commit('SET_PIATTO', resp );
            resolve(resp);
          });
        });
      })
    },
    updateStatoPiatto: function( context, piatto ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('updateStatoPiatto');
          var data={ callname:'updateStatoPiatto', piatto: piatto }
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('updateStatoPiatto:',resp)
            resolve(resp);
          });
        });
      })
    },
    getPrenotazione: function(context){ //tipo
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getPrenotazione');
          var data={ username: context.state.connection.username, callname:'getPrenotazione', crypt: context.state.connection.userdata}; // , tipo: tipo
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getPrenotazione:');
            console.log(resp)
            resolve(resp);
          });
        });
      })
    },
    createPrenotazione: function(context, idEvento){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('createPrenotazione');
          var data={ username: context.state.connection.username, callname:'createPrenotazione', crypt: context.state.connection.userdata, idEvento: idEvento};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('createPrenotazione:');
            console.log(resp)
            resolve(resp);
          });
        });
      })
    },
    annullaPrenotazione: function(context, idEvento){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('annullaPrenotazione');
          var data={ username: context.state.connection.username, callname:'annullaPrenotazione', crypt: context.state.connection.userdata, idEvento: idEvento};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('annullaPrenotazione:');
            console.log(resp)
            resolve(resp);
          });
        });
      })
    },
    getEventi: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getEventi');
          var data={ username: context.state.connection.username, oppty: payload.oppty, callname:'getEventi', crypt: context.state.connection.userdata, tipo: payload.tipo, data: payload.data };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getEventi:');
            console.log(resp)
            resolve(resp);
          });
        });
      })
    },
    getCasi: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCasi');
          var data={ username: context.state.connection.username, callname:'getCasi', crypt: context.state.connection.userdata, idOppty: context.state.idHousing };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getCasi:');
            console.log(resp)
            context.commit('SET_CASI', resp );
            resolve(resp);
          });
        });
      })
    },
    getCategorieCasi: function (context) {
      return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getCategorieCasi')

          var data = {
            username: context.state.connection.username,
            crypt:    context.state.connection.userdata,
            callname: 'getCategorieCasi'
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => {
            console.log('getCategorieCasi response: ' + JSON.stringify(resp))
            context.commit('SET_CATEGORIE_CASI', resp)

            resolve(resp)
          })
        })
      })
    },
    getCondizioni: function(context){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCondizioniTesi');
          var data={callname:'getCondizioniTesi', idOppty: context.state.idOpptyTesi, username: context.state.connection.username, crypt: context.state.connection.userdata};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getCondizioniTesi:');
            console.log(resp);
            if(resp.Id !== undefined){
              context.commit('SET_CONDIZIONE', resp );
            }
            resolve(resp);
          });
        });
      })
    },
    getCondizioneTesi: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCondizioneTesi');
          var data={callname:'getCondizioneTesi', idCorso: payload.idCorso};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getCondizioneTesi resp:', resp);
            resolve(resp);
          });
        });
      })
    },
    getCategoriaIngrAttr:function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCategoriaIngrAttr');
          var data={callname:'getCategoriaIngrAttr', tipo: payload.tipo, condizioneId:context.state.condizione.Id, username: context.state.connection.username, crypt: context.state.connection.userdata};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getCategoriaIngrAttr:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getIngredientiAttrezzaturaTesi: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getIngredientiAttrezzaturaTesi');
          var data={callname:'getIngredientiAttrezzaturaTesi', tipo: payload.tipo, categoria: payload.categoria, condizioneId:context.state.condizione.Id, username: context.state.connection.username, crypt: context.state.connection.userdata};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getIngredientiAttrezzaturaTesi:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getProdEcommerce: function( context, payload ) {
      console.log(payload);
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getProdEcommerce');
          var data={ username: context.state.connection.username, callname:'getProdEcommerce', crypt: context.state.connection.userdata, ricerca: payload.ricerca, filtro: payload.filtro, crit: payload.crit};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            resolve(resp);
          });
        });
      })
    },
    getOrdersEcommerce: function(context) {
      //console.log(payload)

      return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getOrdersEcommerce')

          var data = {
            username: context.state.connection.username,
            crypt: context.state.connection.userdata,
            callname: 'getOrdersEcommerce'
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => resolve(resp))
        })
      })
    },
    recuperaCarrello: function(context) {
      context.commit('GET_CARRELLO')
    },
    addProd: function(context, prod) {
      console.log('addProd')
      console.log(prod) //console.log(context.state.carrelloEcommerce)

      // identifica taglia corretta - controllare con scarpe
      if (prod.taglia) prod.variants.forEach(v => {
        if (v.Sesso_Taglia__c.substring(1) === prod.taglia) {
          prod.Id = v.Id
          prod.Description = v.Description
          prod.ProductCode = v.ProductCode
        }
      })

      if(context.state.carrelloEcommerce != 'null' && context.state.carrelloEcommerce.length > 0) {
        console.log('maggiore di 1')

        if ((context.state.carrelloEcommerce.map(a => a.Id)).includes(prod.Id))
          context.state.carrelloEcommerce.forEach(p => {
            if (p.Id === prod.Id) p.qty += prod.qty
          })
        else
          context.state.carrelloEcommerce.push(JSON.parse(JSON.stringify(prod)))

        console.log(context.state.carrelloEcommerce)
        localStorage.setItem('carrello', JSON.stringify(context.state.carrelloEcommerce))
      } else {
        console.log('uguale a 0')

        context.state.carrelloEcommerce = []
        context.state.carrelloEcommerce.push(JSON.parse(JSON.stringify(prod)))

        context.commit('SET_CARRELLO', context.state.carrelloEcommerce)
      }

      var tot = 0
      context.state.carrelloEcommerce.forEach(d => tot += (d.qty * d.Prezzo__c))
      context.state.totaleEcommerce = tot.toFixed(2)
    },
    removeProd: function(context, index) {
      context.commit('REMOVE_PROD_CARRELLO', index )
    },
    createOpptyEcommerce: function(context, payload) {
      return new Promise(res => {
        context.dispatch('loginbyid').then(() => {
          console.log('createOpptyEcommerce')

          var data = {
            username: context.state.connection.username,
            crypt:    context.state.connection.userdata,

            callname: 'createOpptyEcommerce',

            prods:         payload.products,
            sessionId:     payload.sessionId,
            paymentIntent: payload.paymentIntent,
            importo:       payload.importo,
            isRicarica:    payload.isRicarica
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => res(resp))
        })
      })
    },
    stripePayment: function(context, payload) {
      return new Promise(res => {
        context.dispatch('loginbyid').then(() => {
          console.log('stripePayment')

          var prods = []

          payload.prods
          ? prods = [{
            name: payload.prods[0].Name, descr: payload.prods[0].Description, img: '',
            qty: payload.prods[0].qty, price: payload.prods[0].Prezzo__c, size: ''
          }]
          : context.state.carrelloEcommerce.forEach(p =>
            prods.push({
              name: p.Name, descr: p.Description, img: '',
              qty: p.qty, price: p.Prezzo__c, size: ''
            })
          )

          var data = {
            username: context.state.connection.username,
            crypt: context.state.connection.userdata, // ?

            callname: 'stripePayment',

            tot: payload.tot,
            prods: prods,
            host: window.location.origin,
            lang: payload.locale
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => res(resp))
        })
      })
    },
    getCampi: function( context, oggetti ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCampi');
          var data={ username: context.state.connection.username, callname:'getCampi', crypt: context.state.connection.userdata, oggetti: oggetti };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            var objs=new Map();
            resp.forEach(function(c) {
              if (!objs.has(c.oggetto)) objs.set(c.oggetto, new Map() );
              var o=objs.get(c.oggetto);
              o.set(c.name,c);
            })
            context.commit('SET_CAMPI', objs );
            resolve(resp);
          });
        });
      })
    },
    getProdotti: function( context, productcode ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getProdotti');
          var data={ username: context.state.connection.username, callname:'getProduct', crypt: context.state.connection.userdata, idOppty: productcode };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getProdotti:');
            console.log(resp)
            context.commit('SET_PRODOTTI', resp );
            resolve(resp);
          });
        });
      })
    },
    getScuole: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getScuole');
          var data={ username: context.state.connection.username, callname:'getScuole', crypt: context.state.connection.userdata};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getScuole:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    setCandidatura: function( context, payload ) {
      return new Promise(function(resolve) {
        console.log('setCandidatura');
        var data={ username: payload.account.Username__pc, callname:'setCandidatura', account: payload.account, oppty: payload.oppty, corso: payload.corso, edizione: payload.edizione };
        apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
          console.log('setCandidatura:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },
    setCodiceVoucher: function(context, payload) {
      context.state.vouchercode = payload
    },
    setCandidatoVoucher: function( context, payload ) {
      return new Promise(function(resolve) {
        console.log('setCandidatoVoucher');
        var data={ username: payload.account.Username__pc, callname:'setCandidatoVoucher', vouchercode: context.state.vouchercode, account: payload.account};
        apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
          console.log('setCandidatoVoucher:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },
    spostaOppty: function(context, vouchercode){
      return new Promise(function(resolve) {
        console.log('spostaOppty');
        var data={ username: context.state.connection.username, callname:'spostaOppty', crypt: context.state.connection.userdata, vouchercode: vouchercode };
        apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
          console.log('spostaOppty:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },
    pagaOra: function(context, tipo){
      return new Promise(function(resolve) {
        console.log('creaOppty');
        var lrigheOrd = [];
        context.state.carrelloEcommerce.forEach(function(d,i){
          var r = {Quantita__c: d.qty, Prezzo__c: d.Prezzo__c, Descrizione__c: d.Description,
            Tipo__c: d.Family=='Libri'?'L':'D', Ordinatore__c: i+1};
          if(d.taglia != undefined && d.taglia != '') r.Taglia__c = d.taglia;
          if(d.numero != undefined && d.numero != '') r.Taglia__c = d.numero;
          lrigheOrd.push(r);
        })
        var data={ username: context.state.connection.username, callname:'creaOpptyEcommerce', crypt: context.state.connection.userdata, prods: lrigheOrd , tipo: tipo};
        apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
          console.log('creaOpptyEcommerce:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },
    doRecover: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('doRecover');
          var data={ email: context.state.emailRecovery, callname:'dorecover'};
          apiclient.getcandidatura( context.state.connection, data)
          .then( function(resp) {
            console.log('doRecover resp', resp)
            resolve(resp);
          });
        })
      })
    },
    setNewPwd: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('setNewPwd');
          var data={ tkn: context.state.tkn, newpwd: context.state.newpwd, newpwdconfirm:context.state.newpwdconf, callname:'setnewpwd'};
          console.log('data',data);
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('setnewpwd resp:',resp)
            resolve(resp);
          });
        })
      })
    },
    updateProfilo: function( context ) {
      return new Promise(function(resolve) {
        console.log('updateProfilo');
        var data={ callname:'updateProfilo', username: context.state.connection.username, account: context.state.userprofile };
        apiclient.getcandidatura( context.state.connection, data)
        .then( function(resp) {
          console.log('updateProfilo:',resp)
          context.commit('SET_USERPROFILE_DURTY', false );
          resolve(resp);
        });
      })
    },
    sendtoHB: function( context ) {
      return new Promise(function(resolve) {
        console.log('sendtoHB');
        var data={ username: context.state.connection.username, callname:'sendtoHB', account: context.state.userprofile , edizioneCorso: context.state.productcode};
        apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
          console.log('sendtoHB:');
          console.log(resp);
          context.commit('SET_PURCHASED', true );
          resolve(resp);
        });
      })
    },
    getRegioniProvincie: function(context, tipo){
      return new Promise(function(resolve) {
        console.log('getRegioniProvincie');
        var data={ callname:'getRegioniProvincie', tipo: tipo};
        apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
          console.log('getRegioniProvincie:');
          console.log(resp);
          resolve(resp);
        });
      })
    },
    setCaso: function( context, payload ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('setCaso');
          var data={ username: context.state.connection.username, callname:'upsertCaso', crypt: context.state.connection.userdata, caso: payload };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('setCaso:');
            console.log(resp)
            // context.commit('SET_PIATTO', resp );
            resolve(resp);
          });
        });
      })
    },
    getCorsiAttivi: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCorsiAttivi');
          var data={ username: context.state.connection.username, callname:'getCorsiAttivi', crypt: context.state.connection.userdata };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getCorsiAttivi:');
            console.log(resp)
            context.commit('SET_CORSI', resp );
            resolve();
          });
        });
      })
    },
    getMateriali: function( context, locale ) {
      return new Promise(function(resolve) {
        console.log('getMateriali');
        var language='it_IT';
        if (locale=='en') language='en_EN';
        var idCorsi=[];
        var idOpp=[];
        context.state.corsi.forEach(function(c) {
          idOpp.push(c.Id);
          idCorsi.push(c.Edizione_Corso__r.Corso__c);
        })
        console.log('idCorsi');
        console.log(idCorsi);
        console.log(language);
        var data={ username: context.state.connection.username, callname:'getMateriali', crypt: context.state.connection.userdata, language: language, idCorsi: idCorsi, idOpp: idOpp};
        apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
          console.log('getMateriali:');
          console.log(resp)
          context.commit('SET_MATERIALI', resp );
          resolve();
        });
      });
    },
    getCandidatura: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCandidatura');
          var data={ username: context.state.connection.username, callname:'getCandidatura', crypt: context.state.connection.userdata };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getCandidatura:');
            console.log(resp)
            context.commit('SET_CANDIDATURA', resp );
            resolve();
          });
        });
      })
    },
    getHousing: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getHousing');
          var data={ username: context.state.connection.username, callname:'getHousing', crypt: context.state.connection.userdata };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getHousing:');
            console.log(resp)
            context.commit('SET_HOUSING', resp );
            resolve();
          });
        });
      })
    },
    getDocumenti: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getDocumentiCandidatura');
          var data={ username: context.state.connection.username, callname:'getDocumentiCandidatura', crypt: context.state.connection.userdata, idOppty: context.state.candidatura[0].Id};
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getDocumentiCandidatura:');
            console.log(resp)
            context.commit('SET_DOCUMENTI', resp );
            resolve();
          });
        });
      })
    },
    getPagamenti: function( context ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getPagamenti');
          var data={ username: context.state.connection.username, callname:'getPagamenti', crypt: context.state.connection.userdata };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('getPagamenti:');
            console.log(resp)
            context.commit('SET_PAGAMENTI', resp );
            resolve();
          });
        });
      })
    },

    deregister: function( {commit} ) {
        commit('RESET_USER_DATA');
    },
    register: function( context, data ) {
      apiclient.register( context.state.connection, data).then( function(resp) {
        console.log(resp)
        var connection={
          commit: context.commit,
          username: data.username,
          token: resp.token,
          userdata: resp.userdata,
        };
        context.commit('SET_CONNECTION', connection );
        var user_data={username: data.username, token: resp.userdata};
        context.commit('SET_USER_DATA', user_data );
      });
    },
    checkUser: function( context, data ) {
      return apiclient.query( context.state.connection, data).then( function(resp) {
        console.log(resp);
        if(resp && resp[0].Id != null) context.commit('SET_REG_DENIED', true );
      });
    },
    searchVoucher: function(context, data){
      return new Promise(function(resolve) {
        apiclient.query( context.state.connection, data).then( function(resp) {
          console.log(resp);
          resolve(resp);
        });
      });
    },
    getAbbigliamento: function( context, data ) {
      return apiclient.query( context.state.connection, data).then( function(resp) {
        console.log(resp);
        context.commit('SET_ABBIGLIAMENTO_CANDIDATURA', resp);
      });
    },
    getCategoria: function( context, data ) {
      context.dispatch('loginbyid').then(function() {
        return apiclient.query( context.state.connection, data).then( function(resp) {
          console.log('getcategoria');
          console.log(resp);
          var lcat = [] // = ['Tutte le categorie'];
          resp.forEach(function(d){
            lcat.push(d.Family);
          });
          context.commit('SET_CATEGORIA_ECOMMERCE', lcat);
        });
      })
    },
    /*
    getImages: function (context, ids) {
      return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getProductImages')
          var data = { callname: 'getProductImages', ids: ids }
          apiclient.getcandidatura(context.state.connection, data).then(resp => {
            // console.log('resp:', resp)
            resolve(resp)
          })
        })
      })
    }, */
    getImage: function (context, id) {
      if (this.state.imgEcommerce[id]) return this.state.imgEcommerce[id]
      else return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getProductImage')
          var data = { callname: 'getProductImage', id: id }
          apiclient.getcandidatura(context.state.connection, data).then(resp => {
            this.state.imgEcommerce[id] = resp
            resolve(resp)
          })
        })
      })
    },
    updateCandidatura: function( context, payload ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('updateCandidatura');
          var data={ callname:'updateOpportunity', oppty: payload };
          apiclient.getcandidatura( context.state.connection, data).then( function(resp) {
            console.log('updateOpportunity:');
            console.log(resp);
            if(resp){
              context.state.candidatura[0].Fatturante_compilato__c = true;
            }
            //context.commit('GET_USER_DATA');
            resolve(resp);
          });
        });
      })
    },
    getProfile: function( context ) {
      return new Promise(function(resolve) {
        console.log('getProfile');
        var data={ username: context.state.connection.username, crypt: context.state.connection.userdata };
        apiclient.getprofile( context.state.connection, data).then( function(resp) {
          console.log('getprofile resp')
          console.log(resp);
          context.commit('SET_USER_PROFILE', resp );

          var jwtpayload = jwt.sign( resp, apiclient.secret );
          localStorage.setItem('userprofile', jwtpayload);

          context.commit('GET_USER_DATA', resp );

          resolve();
        });
      })
    },
    login: function( context, data ) {
      return new Promise(function(resolve) {
        apiclient.login( context.state.connection, data).then( function(resp) {
          console.log(resp)
          if(resp.userdata == 'Bloccato'){
            console.log('bloccato');
            context.commit('SET_BLOCKED', true );
          }else{
            var connection={
              commit: context.commit,
              username: data.username,
              token: resp.token,
              userdata: resp.userdata,
            };
            var user_data={username: data.username, token: resp.userdata};
            context.commit('SET_BLOCKED', false );
            context.commit('SET_USER_DATA', user_data );
            context.commit('SET_CONNECTION', connection );
          }
          resolve();
        });
      });
    },
    loginDocenti: function( context, data ) {
      console.log('context', context)
      console.log('data', data)
      return new Promise(function(resolve) {
        apiclient.loginDocenti( context.state.connection, data).then( function(resp) {
          console.log('resp LOGINDOCENTI: ',resp)
          if(resp.userdata == 'Bloccato'){
            console.log('bloccato');
            context.commit('SET_BLOCKED', true );
          }else{
            var connection={
              commit: context.commit,
              username: data.username,
              token: resp.token,
              userdata: resp.userdata,
            };
            console.log(resp.userdata, resp.token)
            var user_data={username: data.username, token: resp.userdata};
            context.commit('SET_BLOCKED', false );
            context.commit('SET_USER_DATA', user_data );
            context.commit('SET_CONNECTION', connection );
          }
          resolve();
        });
      });
    },
    checkOTP: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('checkOTP');
          console.log('context.state.connection',context.state.connection)
          var data={callname:'checkOTP', totp: payload.otp};
          apiclient.getcandidatura( context.state.connection, data)
          .then( function(resp) {
            console.log('checkOTP:'+resp);
            resolve(resp);
          });
        });
      })
    },
    checkExpired: function( context ){
      return new Promise(function(resolve) {
        let isExpired = null;
        let jwtdecoded = null;
        let jwtencoded = localStorage.getItem('userprofile');
        console.log('LocalStorage.userprofile', jwtencoded);
        if(jwtencoded){
          let now = new Date();
          jwtdecoded = jwt.decode(jwtencoded);
          console.log('decodificato', jwtdecoded);
          if(jwtdecoded && jwtdecoded.exp && jwtdecoded.exp > now.getTime()){
            console.log('Sei già loggato, la sessione scade ', jwtdecoded.exp);
            isExpired = false;
          } else {
            isExpired = true;
          }
        }
        console.log('isExpired',isExpired)
        if(isExpired) {
          context.commit('RESET_USER_DATA');
        }

        resolve(isExpired);
      })
    },
    getIdGiurato: function(context){
      return new Promise(function(resolve) {
        // let isExpired = context.dispatch('checkExpired');
        context.dispatch('checkExpired').then( isExpired => {
          console.log('isExpired in getIdGiurato: ', isExpired)
          if(isExpired || isExpired == null) {
            console.log('getIdGiurato');
            context.dispatch('loginbyid').then(function() {
              console.log('context.state.connection',context.state.connection)
              var data={callname:'getIdGiurato'};
              apiclient.getcandidatura( context.state.connection, data)
              .then( function(resp) {
                console.log('getIdGiurato: resp',resp);
                resolve(resp);
              });
            });
          } else {
            console.log('Già loggato');
            resolve({userdata: localStorage.getItem('userdata'), token: localStorage.getItem('token')});
          }
        })
      })
    },
    loginGiurato: function( context, payload ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('loginGiurato');
          var data={ callname:'loginGiurato', crypt: payload.crypt };
          apiclient.getcandidatura( context.state.connection, data)
          .then( function(resp) {
            console.log('loginGiurato:',resp);
            if(resp == null) console.log('Sono finiti gli Account Giurato disponibili')
            else {
              var connection={
                commit: context.commit,
                username: resp.Username__pc,
                token: payload.token,
                userdata: payload.crypt,
              };
              localStorage.setItem('userdata', payload.crypt);
              localStorage.setItem('token', payload.token);
  
              var user_data={username: resp.Username__pc, token: payload.crypt};
              context.commit('SET_USER_DATA', user_data );
              context.commit('SET_CONNECTION', connection );
              context.commit('SET_USER_PROFILE', resp );

              let time = new Date();
              resp['exp'] = time.getTime() + 8 * 1000 * 60 * 60;
              // resp['exp'] = time.getTime() + 10 * 1000;            // TEST
              var jwtpayload = jwt.sign( resp, apiclient.secret );
              localStorage.setItem('userprofile', jwtpayload);
            }

            resolve(resp);
          });
        });
      })
    },
    updateAccount: function( context, payload ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('updateAccount');
          var data={ callname:'updateAccount', account: payload.account };
          apiclient.getcandidatura( context.state.connection, data)
          .then( function(resp) {
            console.log('updateAccount:',resp);
            resolve(resp);
          });
        });
      })
    },
    loginbyid: function( context ) {
      return new Promise(function(resolve) {
        if (context.state.connection.token) {
          resolve();
          return;
        }

        var data={ username: context.state.connection.username, crypt: context.state.connection.userdata };
        apiclient.loginbyid( context.state.connection, data).then( function(resp) {
          console.log(resp)
          var connection={
            commit: context.commit,
            username: data.username,
            token: resp.token,
            userdata: resp.userdata,
          };
          var user_data={username: data.username, token: resp.userdata};
          context.commit('SET_USER_DATA', user_data );
          context.commit('SET_CONNECTION', connection );
          resolve();
        });
      });
    },
    subscribePush: function ( context, subscription ) {
      return new Promise(function(resolve){
        apiclient.subscribePush(subscription).then(function(resp){
          console.log('resp subscribePush', resp)
          
          if(resp.data.success){
            console.log('invio iscrizione notifiche', context.state.connection);
            var data = {callname: 'subscribePush', subscription: JSON.stringify(subscription), crypt: context.state.connection.userdata };
            apiclient.getcandidatura( context.state.connection, data);
            alert('Notifiche attivate con successo');
          }else{
            console.log('il dispositivo è già iscritto alle notifiche');
            alert('Il dispositivo è già iscritto alle notifiche');
          }

          return resolve(resp);
        })
      })
    }
  },
  modules: {
  }
})
