<template>
    <div class="row">
            <div class="col">
                <div class="accordion-item">
                    <h2 class='accordion-header'>
                        <button type="button" id="accordionButton"
                        class="accordion-button rounded-1" style="height:auto;"
                        :class="showInfoIndicatori ? '':'collapsed'" @click="showInfoIndicatori = !showInfoIndicatori">
                            {{ nomeSlot }}
                        </button>
                    </h2>
                    <div id="accordionHeader" class="accordion-collapse collapse" :class="showInfoIndicatori ? 'show':''">
                        <div class="accordion-body py-0" >
                            <studenteUscitaPiatti :layout1="layout1" v-for="t in tesi" v-bind:key="t.Id" :nome="t.Candidato" :numTavolo="t.tavolo" :piatti="t.Piatti" :numerazioneAllievo="t.numerazioneAllievo"
                            @selezionaPiatto="selezionaPiatto($event)"></studenteUscitaPiatti>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 </template>
 
 
 <script>
import studenteUscitaPiatti from './studenteUscitaPiatti.vue';
 
export default {
    name: 'slotUscitaPiatti',
    components: {
        studenteUscitaPiatti
    },
    props: {
        layout1: Boolean,
        nomeSlot: String,
        tesi: Array,
    },
    data() {
        return {
            showInfoIndicatori: false
        }
    },
    computed: {},
    mounted () {
        if(this.nomeSlot.search('1') != -1) this.showInfoIndicatori = true
    },
    methods: {
        selezionaPiatto(piatto) {
            this.$emit('selezionaPiatto', piatto)
        }
    }
}
</script>