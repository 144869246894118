<template>
  <div v-if="$store.getters.logged">
    <div class="container px-3">

      <!-- RIGA NOME -->
      <div class="row mb-3">
        <div class="col">
          <h2 class="fw-light">
            Ciao, <span class="fw-bold">{{ nome }}</span>
          </h2>
        </div>
      </div>

      <!-- CARDS -->
      <div class="row row-cols-2 row-cols-md-6 g-4">
        <div class="col" v-for="card in cards" v-bind:key="card.title" v-bind:value="card">
          <div class="card h-100">
            <div class="card-body">
              <span v-bind:class="['fs-1 text pe-3 '+ card.icon]">
                <a v-if="card.link" class="stretched-link" v-bind:href="card.link"></a>
              </span>
              <h4 class="card-text text-white">{{ card.title }}</h4>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
  name: 'Home',
  components:{},
  data() {
    return {
      nome: '',
      cards: [],
    };
  },
  mounted: function() {
    this.cards = this.$store.getters.abilitazioni
  },
  created() {
    this.nome = localStorage.getItem('nome');
  },
  methods: {}
}
</script>
